import { useEffect, useMemo, useState } from 'react';

import { Cascader, CascaderMenuOption, Icon } from '@tencent/tea-component';

import styled from 'styled-components';

import { NumberList } from 'src/services/httpAPIs/pstn';
import { useRequest } from 'src/services/httpClient';

const toOptions = (data: NumberList): CascaderMenuOption[] => {
  const addChild = (parent: CascaderMenuOption, child: any) => {
    if (!parent.children) {
      parent.children = [];
    }
    parent.children.push({
      label: child.numberText,
      value: child.number,
    });
  };
  const result: CascaderMenuOption[] = [];
  for (const num of data) {
    const { number, numberText, location } = num;
    // 未知归属地号码
    if (!location) {
      result.push({
        label: numberText,
        value: number,
      });
    }
    let countryOpt = result.find((item) => item.value === location);
    if (!countryOpt) {
      countryOpt = {
        label: location,
        value: location,
        children: [],
      };
      result.push(countryOpt);
    }
    addChild(countryOpt, { location, number: num.number, numberText: num.numberText });
  }
  return result;
};

interface PhoneSelectorProps {
  value?: string;
  onChange?: (value: string) => void;
}

const StyledBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export function PhoneSelector({ value = '', onChange }: PhoneSelectorProps) {
  const [phoneList, setPhoneList] = useState<any>([]);

  const { data, loading } = useRequest('/ccc/pstn/getStaffCanCalloutNumberList', {});

  useEffect(() => {
    if (!data.numberList) {
      return;
    }
    const phoneList = toOptions(data.numberList).sort(
      (a, b) => (a?.label as string)?.charCodeAt(0) - (b?.label as string)?.charCodeAt(0),
    );
    setPhoneList(phoneList);
  }, [data]);

  const optionsValue = useMemo(() => {
    // 给定一个字符，找出它在 phoneList中对应的路径
    const findPath = (phoneList: CascaderMenuOption[], phone: string): string[] => {
      for (const item of phoneList) {
        if (item.children) {
          const path = findPath(item.children, phone);
          if (path.length) {
            return [item.value, ...path];
          }
        } else if (item.value === phone) {
          return [item.value];
        }
      }
      return [];
    };
    return findPath(phoneList, value);
  }, [phoneList, value]);

  const handleCascaderChange = (value: string[]) => {
    const phone = value.slice(-1)[0];
    onChange?.(phone);
  };

  const handleValueRender = (options: CascaderMenuOption[]) => {
    const leaf = options[options.length - 1];
    return leaf?.label || leaf?.value;
  };

  return (
    <StyledBox>
      <Cascader
        clearable
        searchable
        expandTrigger="hover"
        type="menu"
        data={phoneList}
        onChange={handleCascaderChange}
        value={optionsValue}
        valueRender={handleValueRender}
      />
      {loading && <Icon type="loading" style={{ marginLeft: 10 }} />}
    </StyledBox>
  );
}
