import { message, Modal } from '@tencent/tea-component';
import copy from 'copy-to-clipboard';
import { TFunction } from 'i18next';

import { isHaveRoleById, SpecialRoleId } from 'tccc-utils/src/userRole';

import { ERROR_CODES } from 'src/constants/manage';
import { UserInfo } from 'src/store/slices/userInfo';

import { getFullHost } from 'src/utils/urls';

import { checkIsDeskAuthing } from './index';

type TType = TFunction<'translation', undefined>;

const handleCopy = (t: TType, copyLink: string) => {
  copy(copyLink, {
    format: 'text/plain',
    onCopy() {
      message.success({ content: t('复制成功') });
    },
  });
};

const handleCancel = (openLink: string, roleId?: string) => {
  if (!isHaveRoleById(roleId, SpecialRoleId.ADMIN)) {
    return;
  }
  window.location.href = openLink;
};

const handleLackOfSeat = (error: any, t: TType) => {
  const [head, tail] = error.message.split(t('请到控制台')) || [];
  return message.error({
    content: (
      <span>
        {head}
        <a target="_blank" href="https://buy.cloud.tencent.com/ccc_seat" rel="noreferrer">
          {t('请到控制台')}
        </a>
        {tail}
      </span>
    ),
    duration: 10000,
  });
};

const handleLackOfLicense = (t: TType, userInfo: UserInfo, roleId?: string) => {
  const { sdkAppId } = userInfo;
  const copyLink = `${getFullHost()}/saas-manage/${sdkAppId}/seat-management?tab=enabled`;
  const description = t('请复制链接发送链接给管理员或管理员直接前往客服管理查看。');
  const openLink = `${getFullHost()}/saas-manage/${sdkAppId}/seat/seat-management?tab=enabled`;
  Modal.confirm({
    message: t('座席许可数不足'),
    description,
    icon: 'error',
    onOk: () => handleCopy(t, copyLink),
    okText: t('复制链接'),
    onCancel: () => handleCancel(openLink, roleId),
    cancelText: isHaveRoleById(roleId, SpecialRoleId.ADMIN) ? t('立即前往') : t('返回'),
  });
};

const handleSeatPkgInvalid = (t: TType, roleId?: string) => {
  const copyLink = 'https://console.cloud.tencent.com/ccc/seat';
  const description = t('请复制链接发送链接给管理员或管理员直接前往客服管理查看。');
  const openLink = 'https://console.cloud.tencent.com/ccc/seat';
  Modal.confirm({
    message: t('座席套餐已失效'),
    description,
    icon: 'error',
    onOk: () => handleCopy(t, copyLink),
    okText: t('复制链接'),
    onCancel: () => handleCancel(openLink, roleId),
    cancelText: isHaveRoleById(roleId, SpecialRoleId.ADMIN) ? t('立即前往') : t('返回'),
  });
  return;
};

export const handleLoginError = (error: any, t: TType, userInfo: UserInfo, roleId?: string) => {
  const isDeskAuthing = checkIsDeskAuthing();
  if (error.code === ERROR_CODES.LACK_OF_SEAT) {
    return handleLackOfSeat(error, t);
  }
  if (error.code === ERROR_CODES.LACK_OF_LICENCE) {
    return handleLackOfLicense(t, userInfo, roleId);
  }
  if (error.code === ERROR_CODES.SEAT_PKG_INVALID) {
    return handleSeatPkgInvalid(t, roleId);
  }
  if (isDeskAuthing) {
    return Modal.confirm({
      message: t('认证失败'),
      description: error?.message || t('登录失败，请重试'),
      icon: 'error',
      onOk: () => window.close(),
      okText: t('知道了'),
      cancelText: '',
      onCancel: () => window.close(),
    });
  }
  return message.error({
    content: error?.message || t('登录失败，请重试'),
  });
};
