import { useEffect, useMemo, useState } from 'react';

import { Avatar, makeStyles } from '@material-ui/core';
import { Button, Form, Input, InputAdornment, Modal } from '@tencent/tea-component';
import { useField, useForm } from 'react-final-form-hooks';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { getTeaFormStatus as getStatus, isInternational } from 'tccc-utils';
import { getRoleNameList } from 'tccc-utils/src/userRole';

import AvatarIcon from 'src/assets/images/head.png';
import ConfirmDialog from 'src/components/BaseDialog/ConfirmDialog';

import { getSession } from 'src/components/VerifyCode/captchaSessionMange';
import VerifyCode from 'src/components/VerifyCode/VerifyCode';
import i18n from 'src/i18n';
import { request } from 'src/services/httpClient';
import { RootState } from 'src/store';
import { bindMobile, updateMobile } from 'src/store/slices/userInfo';
import { isDeskSeat } from 'src/utils';
import { formatTel } from 'src/utils/formatTel';

const StyledInput = styled(Input)`
  width: 250px;
`;

const useStyles = makeStyles(() => ({
  form: {
    '& label': {
      width: 68,
    },
  },
}));

const { t } = i18n;

const validate = (
  {
    oldPhoneNumberVerify,
    newPhoneNumber,
    captchaCode,
  }: {
    newPhoneNumber: string;
    captchaCode: string;
    oldPhoneNumberVerify?: string;
  },
  isBindMobile: boolean,
  oldPhoneNumber: string,
  isDeskAdminAddMobile: boolean,
) => {
  const errors: any = {};
  const oldNumber = oldPhoneNumberVerify?.startsWith('0086') ? oldPhoneNumberVerify : `0086${oldPhoneNumberVerify}`;
  if (isBindMobile && !isDeskAdminAddMobile && oldNumber !== oldPhoneNumber) {
    errors.oldPhoneNumberVerify = t('请输出正确的原手机号：{{0}}', { 0: formatTel(oldPhoneNumber, '', true) });
  }

  if (!(parseInt(newPhoneNumber, 10) > 0 && newPhoneNumber?.length >= 10)) {
    errors.newPhoneNumber = t('请输入正确的手机号码');
  }
  if (!captchaCode) {
    errors.captchaCode = t('请输入正确的验证码');
  }
  if (captchaCode && !getSession(newPhoneNumber)) {
    errors.captchaCode = t('验证码已过期');
  }
  return errors;
};

const ChangeMobileDialog = ({
  open,
  oldPhoneNumber,
  changePhoneNumber,
  onClose,
}: {
  open: boolean;
  oldPhoneNumber: string;
  changePhoneNumber: (input: { newPhoneNumber: string; captchaCode: string }) => void;
  onClose: () => void;
}) => {
  const { isBindMobile, mobile } = useSelector((state: RootState) => state.userInfo);
  // 智能客服的预设管理员做了特殊处理，isBindMobile为true，mobile为空，此时需要绑定手机号
  const isDeskAdminAddMobile = isBindMobile && mobile === '';
  const { form, handleSubmit, validating, submitting } = useForm<{
    newPhoneNumber: string;
    captchaCode: string;
    oldPhoneNumberVerify: string;
  }>({
    onSubmit: (input: { newPhoneNumber: string; captchaCode: string }) => {
      changePhoneNumber(input);
      onClose();
    },
    initialValues: { oldPhoneNumberVerify: '', newPhoneNumber: '', captchaCode: '' },
    validate: (values) => validate(values, isBindMobile, oldPhoneNumber, isDeskAdminAddMobile),
  });
  const oldPhoneNumberVerifyField = useField('oldPhoneNumberVerify', form);
  const newPhoneNumberField = useField('newPhoneNumber', form);
  const captchaCodeField = useField('captchaCode', form);

  useEffect(() => {
    if (open) {
      form.reset();
      form.resetFieldState('oldPhoneNumberVerify');
      form.resetFieldState('newPhoneNumber');
      form.resetFieldState('captchaCode');
    }
  }, [form, open]);
  return (
    <Modal visible={open} size="s" disableCloseIcon onClose={onClose} caption={t('修改手机号')}>
      <Modal.Body>
        <Form layout="fixed">
          {isBindMobile && !isDeskAdminAddMobile && (
            <Form.Item label={t('原手机号码')} {...getStatus(oldPhoneNumberVerifyField.meta)}>
              <Input
                size="full"
                placeholder={t('请输入{{0}}的完整手机号', { 0: formatTel(oldPhoneNumber, '', true) })}
                {...oldPhoneNumberVerifyField.input}
              />
            </Form.Item>
          )}
          <Form.Item label={t('新手机号码')} {...getStatus(newPhoneNumberField.meta)}>
            <Input size="full" {...newPhoneNumberField.input} />
          </Form.Item>
          <Form.Item label={t('手机验证码')} {...getStatus(captchaCodeField.meta)}>
            <InputAdornment
              after={
                <VerifyCode
                  mobile={newPhoneNumberField.input.value}
                  module={isBindMobile && !isDeskAdminAddMobile ? 'changeMobile' : 'bindMobile'}
                />
              }
            >
              <Input size="s" {...captchaCodeField.input} />
            </InputAdornment>
          </Form.Item>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button loading={submitting} type="primary" disabled={validating} onClick={handleSubmit as any}>
          {t('确定')}
        </Button>
        <Button type="weak" onClick={onClose}>
          {t('取消')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export const MyProfile: React.FC<{ open: boolean; setOpen: (open: boolean) => void }> = (props) => {
  const userInfo = useSelector((state: RootState) => state.userInfo);
  const appSettings = useSelector((state: RootState) => state.appSettings);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(false);
  const [roleListName, setRoleListName] = useState('');
  const isDemo = useMemo(() => userInfo.userId.endsWith('@tcccdemo.com'), [userInfo.userId]);
  const userMobile = useMemo(() => {
    let { mobile } = userInfo;
    if (isDemo) {
      mobile = userInfo.userId.replace(/^0086(.*?)@tcccdemo.com/, '$1');
    }
    return formatTel(mobile, undefined, true);
  }, [userInfo, isDemo]);

  const isDesk = useMemo(
    () => isDeskSeat(appSettings?.seatType, appSettings?.imAppType),
    [appSettings?.imAppType, appSettings?.seatType],
  );

  const handleChangePhoneNumber = ({
    newPhoneNumber: mobile,
    captchaCode: smsCaptchaCode,
  }: {
    newPhoneNumber: string;
    captchaCode: string;
  }) => {
    // 智能客服的预设管理员做了特殊处理，isBindMobile为true，mobile为空，此时需要绑定手机号
    const isDeskAdminAddMobile = userInfo.isBindMobile && userInfo.mobile === '';
    if (userInfo.isBindMobile && !isDeskAdminAddMobile) {
      dispatch(updateMobile({ mobile, oldMobile: userInfo.mobile?.replace(/^0086/, ''), smsCaptchaCode }));
    } else {
      dispatch(bindMobile({ mobile, smsCaptchaCode }));
    }
  };

  useEffect(() => {
    if (props.open) {
      request('/tcccadmin/staff/getRoleList', {}).then(({ roleList }) => {
        const list = getRoleNameList(roleList, userInfo.roleId);
        if (list.length > 0) {
          setRoleListName(list.join(','));
          return;
        }
        setRoleListName('-');
      });
    }
  }, [props.open, userInfo.roleId]);

  return (
    <>
      <ChangeMobileDialog
        open={isEditingPhoneNumber}
        oldPhoneNumber={userInfo.mobile}
        changePhoneNumber={handleChangePhoneNumber}
        onClose={() => {
          setIsEditingPhoneNumber(false);
        }}
      />
      <ConfirmDialog
        size="s"
        title={isInternational() ? t('查看个人信息') : t('修改个人信息')}
        open={props.open && !isEditingPhoneNumber}
        onConfirm={() => props.setOpen(false)}
        onCancel={() => props.setOpen(false)}
        onClose={() => props.setOpen(false)}
        cancelText={null}
      >
        <Form className={classes.form}>
          <Form.Item align="middle" label={t('头像')}>
            <Form.Control>
              <Avatar src={AvatarIcon}></Avatar>
            </Form.Control>
          </Form.Item>
          <Form.Item label={isDesk ? t('昵称') : t('姓名')}>
            <StyledInput disabled value={isDesk ? userInfo?.nickName : userInfo?.staffName} />
          </Form.Item>
          {!isDesk && (
            <Form.Item label={t('工号')}>
              <StyledInput disabled value={userInfo.staffNo} />
            </Form.Item>
          )}
          <Form.Item label={t('角色')}>
            <StyledInput disabled value={roleListName} />
          </Form.Item>
          {!isInternational() && (
            <Form.Item
              label={t('手机号')}
              suffix={
                !isDemo && (
                  <Button type="link" disabled={isEditingPhoneNumber} onClick={() => setIsEditingPhoneNumber(true)}>
                    &nbsp;{t('修改')}
                  </Button>
                )
              }
            >
              <StyledInput disabled value={userMobile} />
            </Form.Item>
          )}
        </Form>
      </ConfirmDialog>
    </>
  );
};
