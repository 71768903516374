import { isValidElement, useEffect, useState } from 'react';

import { Avatar, Badge, ClickAwayListener, IconButton, makeStyles } from '@material-ui/core';
import { isRejected } from '@reduxjs/toolkit';
import { message, notification } from '@tencent/tea-component';
import classnames from 'classnames';
import copy from 'copy-to-clipboard';
import debounce from 'lodash/debounce';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { UserStatusEnum, UserStatusType } from 'tccc-utils';

import { ulid } from 'ulid';

import CustomPopper from 'src/components/CustomPopper/CustomPopper';
import { UserStatusMap } from 'src/constants/agent';
import i18n from 'src/i18n';
import { judgeInWhitelistByKey } from 'src/services/httpClient';
import store, { RootState, selectAllSession } from 'src/store';
import { offline, online } from 'src/store/actions/agent';
import { Direction } from 'src/store/slices/sessions';
import { sendMessageToIframe } from 'src/utils/communicate';
import enums from 'src/utils/enums';
import { goldlog } from 'src/utils/goldlog';
import { logger } from 'src/utils/log';
import sessionManage from 'src/utils/sessionManage';

import tccc from 'src/utils/tccc';
import CallOut from 'src/views/Agent/CallOut/CallOut';

import { CallOutSvg } from '../Assets/CallOut.svg';
import { CRMSvg } from '../Assets/CRM.svg';
import { ManageSvg } from '../Assets/Manage.svg';

import { AssignState } from '../AssignState/AssignState';

import { UnderFeatureToggle } from '../UnderFeatureToggle/UnderFeatureToggle';

import { AccountSettings } from './AccountSettings/AccountSettings';

import { useStyles } from './AgentTopBar.style';
import { AsrFeatureTips } from './AsrFeatureTips';
import { NoiseReduction, useAINoiseReductionToggle } from './NoiseReduction';

const LogInteraction = goldlog.of('SEAT').forType('INTERACTION');

const { statusColor } = enums;

const ManageView = 'manage';
const AgentView = 'agent';
const demoView = 'demo';
const RecordView = 'record';
const CRMView = 'crm';
const { t } = i18n;

const debounceReportStore = debounce(
  () => {
    try {
      const storeState = store.getState();
      const referenceId = ulid();
      logger.error(`导出store ${referenceId} [${JSON.stringify(storeState)}]`, { referenceId });
      copy(`referenceID: ${referenceId}`, {
        message: t('导出成功，已复制 ReferenceId 到剪贴板'),
      });
      notification.success({
        title: t('导出成功'),
        description: t('数据上报成功，已复制 ReferenceId 到剪贴板：{{0}}', { 0: referenceId }),
        duration: 10 * 1000,
      });
    } catch {
      // ignore
    }
  },
  10 * 1000,
  { leading: true, trailing: false },
);

export function getStateText(status: UserStatusType, reserveRest?: any) {
  if (status === 'arrange') return t('结束话后整理');
  if (status === 'rest' || reserveRest) return t('结束小休');
  return t('小休');
}
export function useAgentTopBarState() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { status, reason, nextStatus, logicStatus, connectionState } = useSelector((state: RootState) => state.agent);
  const { staffNo, nickName, isBindMobile, canUseMobile } = useSelector((s: RootState) => s.userInfo);
  const { restReasonList: reasonList } = useSelector((s: RootState) => s.appSettings);
  const restReasonList = reasonList?.filter((s) => s.state === '1');
  const { t } = useTranslation();

  const resetSaasDemoLogin = () => {
    localStorage.clear();
    sessionManage.clearSession();
    sendMessageToIframe({
      type: 'reset',
    });
    window.location.href = 'https://tccc.qcloud.com/demo?from=exp';
  };
  async function onHandleExit() {
    LogInteraction.doing('SET_STATUS').with(t('点击退出登录'));
    const isFromExp = queryString.parse(window.location.search)?.from === 'exp';
    try {
      await dispatch(offline());
      if (isFromExp) {
        sendMessageToIframe({
          type: 'reset',
        });
      }
    } catch (e) {
      console.error(e);
    }
    sessionManage.loginOut();
  }

  async function onHandleOnline() {
    LogInteraction.doing('SET_STATUS').with(t('切换上线状态'));
    const isFromExp = queryString.parse(window.location.search)?.from === 'exp';
    const res = await dispatch(online());
    if (isRejected(res)) {
      if (isFromExp) {
        resetSaasDemoLogin();
      } else {
        message.error({
          content: res.error.message,
        });
      }
    }
  }

  function onHandleOffline() {
    LogInteraction.doing('SET_STATUS').with(t('切换下线状态'));
    dispatch(offline());
  }
  function onHandleSwitch() {
    LogInteraction.doing('SET_STATUS').with(t('切换应用'));
    onHandleOffline();
    history.push('/online');
  }

  function setReady() {
    LogInteraction.doing('SET_STATUS').with(t('切换示闲状态'));
    tccc.Agent.setStatus('free');
  }

  function setRest(restReason: string) {
    LogInteraction.doing('SET_STATUS').with({ target: restReason }, t('切换小休状态'));
    tccc.Agent.setStatus('rest', restReason);
  }

  function setBusy() {
    if (nextStatus === UserStatusMap.notReady) {
      LogInteraction.doing('SET_STATUS').with(t('切换停止示忙状态'));
      tccc.Agent.setStatus('stopNotReady');
    } else {
      LogInteraction.doing('SET_STATUS').with(t('切换停止小休状态'));
      tccc.Agent.setStatus('stopRest');
    }
  }

  function setDisplayBusy() {
    LogInteraction.doing('SET_STATUS').with(t('切换示忙状态'));
    tccc.Agent.setStatus('notReady');
  }

  function cancelNotReady() {
    LogInteraction.doing('SET_STATUS').with(t('切换停止示忙状态'));
    tccc.Agent.setStatus('stopNotReady');
  }

  return {
    status,
    nextStatus,
    logicStatus,
    connectionState,
    reason,
    restReasonList,
    setRest,
    setReady,
    setDisplayBusy,
    setBusy,
    cancelNotReady,
    onHandleOffline,
    onHandleOnline,
    onHandleExit,
    onHandleSwitch,
    nickName,
    staffNo,
    isBindMobile,
    canUseMobile,
  };
}
const useBadgeStyles = makeStyles(() => ({
  dot: {
    right: 4,
    bottom: 4,
    // @ts-ignore
    backgroundColor: (props) => statusColor[props.status],
  },
}));

export function Right(props: { setShow?: any; show?: any; fromSdk?: boolean; head?: any }) {
  const { status } = useAgentTopBarState();
  const badgeClasses = useBadgeStyles({ status });
  const [lastClickTimeStamp, setLastClickTimeStamp] = useState(Date.now());
  const [clickCounter, setClickCounter] = useState(0);
  const {
    isNoiseReductionEnabled,
    isAiNoiseReductionInWhiteList,
    turnOffNoiseReduction,
    turnOnNoiseReduction,
    trackPopupOpen,
  } = useAINoiseReductionToggle();

  useEffect(() => {
    const nowTimeStamp = Date.now();
    if (clickCounter === -1) {
      setClickCounter(0);
      setLastClickTimeStamp(nowTimeStamp);
      return;
    }
    if (nowTimeStamp - lastClickTimeStamp > 3 * 1000) {
      setClickCounter(0);
      setLastClickTimeStamp(nowTimeStamp);
      return;
    }
    if (clickCounter === 5) {
      try {
        debounceReportStore();
      } catch {
        // do nothing
      }
    }
  }, [clickCounter, lastClickTimeStamp]);
  return (
    <>
      <UnderFeatureToggle
        featureToggle={{
          phoneCallEnabled: true,
          videoCallEnabled: true,
          audioCallEnabled: true,
        }}
      >
        <NoiseReduction
          {...{
            isNoiseReductionEnabled,
            isAiNoiseReductionInWhiteList,
            turnOffNoiseReduction,
            turnOnNoiseReduction,
            trackPopupOpen,
          }}
        />
      </UnderFeatureToggle>
      {!props.fromSdk && status !== UserStatusEnum.offline && <AssignState />}
      <CustomPopper
        renderPopper={
          <AccountSettings
            fromSdk={props.fromSdk}
            {...{
              isNoiseReductionEnabled,
              isAiNoiseReductionInWhiteList,
              turnOffNoiseReduction,
              turnOnNoiseReduction,
              trackPopupOpen,
            }}
          />
        }
      >
        <IconButton onClick={() => setClickCounter((c) => (c > 5 ? -1 : c + 1))}>
          <Badge
            overlap="rectangular"
            variant={'dot'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            classes={badgeClasses}
          >
            {isValidElement(props.head) ? props.head : <Avatar src={props.head} />}
          </Badge>
        </IconButton>
      </CustomPopper>
      {!props.fromSdk && <div style={{ color: '#fff', opacity: 0.7 }}>{enums.agentType?.[status]}</div>}
    </>
  );
}

export function Left() {
  const history = useHistory();
  const { currentView, sdkAppId } = useParams<{ currentView: string; sdkAppId: string }>();
  const { permissionList, skillGroupId, hasAdmin } = useSelector((state: RootState) => state.userInfo);
  const [popUp, setPopUp] = useState(false);
  const { crmEnabled, crmGray } = useSelector((state: RootState) => state.appSettings);
  const { status } = useAgentTopBarState();
  const classes = useStyles({ currentView, popup: popUp });
  const [hideRecord, setHideRecord] = useState(true); // 默认隐藏，无配置则显示
  const isFromSdk = window.location.search.includes('fromSdk');
  const sessions = useSelector(selectAllSession);

  const callInfo = sessions.find(
    (item) =>
      ['phone', 'internal'].includes(item.type) && item.direction === Direction.callOut && item.status !== '400',
  );
  const openCallOut = popUp && !callInfo;
  const currentSeatId = useSelector((state: RootState) => state.userInfo.userId);
  const isDemoAccount = currentSeatId?.endsWith('@tcccdemo.com');
  const isSaasDemoPage = window.location.pathname.includes('/demo');
  const { t } = useTranslation();
  useEffect(() => {
    if (sdkAppId) {
      judgeInWhitelistByKey('hideRecord', sdkAppId).then(setHideRecord);
    }
  }, [sdkAppId]);
  return (
    <>
      <UnderFeatureToggle featureToggle={{ phoneCallEnabled: true }}>
        {((!isFromSdk && skillGroupId.length > 0) || isSaasDemoPage) && ( // 当前座席没有技能组时 不应该展示拨号按钮
          <>
            <ClickAwayListener onClickAway={() => setPopUp(false)}>
              <div className={classes.calloutWrapper}>
                <div className={classes.callout} style={{ display: openCallOut ? 'block' : 'none' }}>
                  {popUp && !callInfo && <div className={classes.calloutArrow} />}
                  <CallOut open={popUp && !callInfo} />
                </div>
                <AsrFeatureTips>
                  <IconButton
                    className={classes.callOutSvg}
                    onClick={() => {
                      if (status === UserStatusEnum.busy) {
                        message.warning({
                          content: t('当前状态不能外呼，请先结束正在进行中的服务。'),
                        });
                        return;
                      }
                      setPopUp((e) => !e);
                      LogInteraction.doing('CLICK_PLAY').with(t('点击外呼按钮'));
                    }}
                  >
                    <CallOutSvg active={popUp} />
                    <div>{t('电话外呼')}</div>
                  </IconButton>
                </AsrFeatureTips>
              </div>
            </ClickAwayListener>
            <div className={classes.line} />
          </>
        )}
      </UnderFeatureToggle>
      {!isFromSdk && (
        <IconButton
          className={classnames(classes.logo, classes.workbenchSvg)}
          onClick={() => {
            LogInteraction.doing('CLICK_JUMP').with(isDemoAccount ? t('点击跳转Demo工作台') : t('点击跳转工作台'));
            if (currentView === AgentView || currentView === demoView) return;
            isDemoAccount ? history.push(`/${demoView}/${sdkAppId}`) : history.push(`/${AgentView}/${sdkAppId}`);
          }}
        >
          <div className="WorkbenchImg" />
          <div>{t('工作台')}</div>
        </IconButton>
      )}
      {!isFromSdk && !hideRecord && (
        <IconButton
          className={classnames(classes.logo, classes.recordSvg)}
          onClick={() => {
            LogInteraction.doing('CLICK_JUMP').with(t('点击跳转服务记录'));
            history.push(`/${RecordView}/${sdkAppId}`);
          }}
        >
          <div className="recordImg" />
          <div>{t('服务记录')}</div>
        </IconButton>
      )}

      {/* 管理员或质检员或不是新版智能客服才能点击跳转管理端 */}
      {!isFromSdk && !hideRecord && hasAdmin && (
        <IconButton
          className={classnames(classes.logo, classes.manageSvg)}
          onClick={() => {
            LogInteraction.doing('CLICK_JUMP').with(t('点击跳转管理端'));
            if (currentView === ManageView) return;
            window.open('/saas-manage');
          }}
        >
          <ManageSvg active={currentView === ManageView} />
          <div>{t('管理端')}</div>
        </IconButton>
      )}
      {!isFromSdk && !hideRecord && permissionList.includes('CRM_ALL') && (crmEnabled || crmGray) && (
        <IconButton
          className={classnames(classes.logo, classes.crmSvg)}
          onClick={() => {
            LogInteraction.doing('CLICK_JUMP').with(t('点击跳转CRM'));
            if (currentView === CRMView) return;
            window.open(`/saas-manage/${sdkAppId}/crm/profile-management`);
          }}
        >
          <CRMSvg active={currentView === CRMView} />
          <div>{t('客户管理')}</div>
        </IconButton>
      )}
    </>
  );
}
