import { cloneElement, ReactElement } from 'react';

import { isRejected } from '@reduxjs/toolkit';
import { message } from '@tencent/tea-component';
import { useTranslation } from 'react-i18next';

import { hideConfirmDialog, showConfirmDialog } from 'src/components/universal/ConfirmDialogTips/ConfirmDialogTips';
import { useAppDispatch } from 'src/store';

import { goldlog } from 'src/utils/goldlog';

import { addToBlackList } from '../../store/slices/sessions/sessions.thunk';
const LogInteraction = goldlog.of('SEAT').forType('INTERACTION');

function BlackList({ sessionId, children }: { sessionId: string; children: ReactElement }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const showConfirm = function () {
    LogInteraction.doing('CLICK_TRIGGER').with(t('点击添加黑名单'));
    showConfirmDialog({
      title: t('确认添加至黑名单吗?'),
      content: t('添加后，用户发送的消息将会被系统自动拦截，并提示系统繁忙。该操作只有系统管理员才能撤销。'),
      onEnter() {
        dispatch(addToBlackList({ sessionId })).then((res) => {
          if (isRejected(res)) {
            message.error({
              content: res.error.message || t('添加黑名单失败，请重试'),
            });
          } else {
            message.success({
              content: t('添加黑名单成功'),
            });
          }
          hideConfirmDialog();
        });
      },
      onCancel() {
        hideConfirmDialog();
      },
    });
  };

  return cloneElement(children, {
    onClick: showConfirm,
  });
}

export default BlackList;
