import { isCn, isComCn, isInternational, isDev, isProd, isTest } from './env';
import {
  AEGIS_ID,
  AEGIS_INTL_ID,
  AEGIS_URL,
  AEGIS_INTL_URL,
  API_BAK_PROD_DOMAIN,
  API_CN_DOMAIN,
  API_COM_CN_DOMAIN,
  API_INTL_DOMAIN,
  API_PROD_DOMAIN,
  CAPTCHA_INTL_LOGIN_ID,
  CAPTCHA_INTL_MOBILE_ID,
  CAPTCHA_INTL_URL,
  CAPTCHA_LOGIN_ID,
  CAPTCHA_MOBILE_ID,
  CAPTCHA_URL,
  OTLP_DOMAIN,
  OTLP_ID,
  OTLP_INTL_DOMAIN,
  OTLP_INTL_ID,
  DESK_DEV_DOMAIN,
  DESK_PROD_DOMAIN,
  DESK_INTL_DOMAIN,
  DEV_DOMAIN,
  TEST_DOMAIN,
  INTL_DOMAIN,
  PROD_DOMAIN,
} from './constant';

export const GET_API_DOMAIN = (origin?: string) => {
  if (isInternational(origin)) {
    return API_INTL_DOMAIN;
  }
  if (isComCn(origin)) {
    return API_COM_CN_DOMAIN;
  }
  if (isCn(origin)) {
    return API_CN_DOMAIN;
  }
  return API_PROD_DOMAIN;
};
export const GET_API_BAK_DOMAIN = (origin?: string) => {
  // 没有备用域名
  if (isInternational(origin) || isComCn(origin) || isCn(origin)) {
    return GET_API_DOMAIN(origin);
  }
  return API_BAK_PROD_DOMAIN;
};

export const GET_OTLP_DOMAIN = (origin?: string) => {
  if (isInternational(origin)) {
    return OTLP_INTL_DOMAIN;
  }
  return OTLP_DOMAIN;
};

export const GET_OTLP_ID = (origin?: string) => {
  if (isInternational(origin)) {
    return OTLP_INTL_ID;
  }
  return OTLP_ID;
};
export const GET_AEGIS_ID = (origin?: string) => {
  if (isInternational(origin)) {
    return AEGIS_INTL_ID;
  }
  return AEGIS_ID;
};

export const GET_AEGIS_URL = (origin?: string) => {
  if (isInternational(origin)) {
    return AEGIS_INTL_URL;
  }
  return AEGIS_URL;
};

export const GET_CAPTCHA_URL = (origin?: string) => {
  if (isInternational(origin)) {
    return CAPTCHA_INTL_URL;
  }
  return CAPTCHA_URL;
};

export const GET_MOBILE_CAPTCHA_ID = (origin?: string) => {
  if (isInternational(origin)) {
    return CAPTCHA_INTL_MOBILE_ID;
  }
  return CAPTCHA_MOBILE_ID;
};

export const GET_LOGIN_CAPTCHA_ID = (origin?: string) => {
  if (isInternational(origin)) {
    return CAPTCHA_INTL_LOGIN_ID;
  }
  return CAPTCHA_LOGIN_ID;
};

export const GET_ADMIN_DOMAIN = ({ origin, imAppType }: { origin?: string; imAppType: number }) => {
  if (imAppType === 1) {
    if (isDev) {
      return DESK_DEV_DOMAIN;
    }
    if (isInternational(origin)) {
      return DESK_INTL_DOMAIN;
    }
    if (isProd) {
      return DESK_PROD_DOMAIN;
    }
  }
  if (isDev) {
    return DEV_DOMAIN;
  }
  if (isTest) {
    return TEST_DOMAIN;
  }
  if (isInternational(origin)) {
    return INTL_DOMAIN;
  }
  return PROD_DOMAIN;
};
