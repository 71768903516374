import { Message } from 'tccc-sdk';

export type CallState = 'INIT' | 'RINGING' | 'ACCEPTED' | 'END';
export type Member = {
  memberId: string;
  callState: CallState;
  displayName?: string;
  hungupParams?: {
    side: 'REMOTE' | 'LOCAL' | 'SYSTEM';
    message: string;
    subMessage: string;
  };
  acceptTimestamp: number;
  hungupTimestamp: number;
};
export type MemberAcceptedState = {
  ON_HOLD: boolean;
  MUTE: boolean;
  INPUT: boolean;
  PLAY_AUDIO: boolean;
};

export type SeatMember = Member & {
  userId: string;
  serverType: ServerType;
  extra: {
    skillGroupId?: string;
  };
  acceptedState: MemberAcceptedState;
  waitId?: string;
};
export type OutboundMember = Member & {
  serverType: 'outboundSeat';
  phone?: string;
  protectedPhone?: string;
  extra: {
    callerLocation?: string;
  };
  acceptedState: MemberAcceptedState;
};

export type QueueMember = Member & {
  serverType: 'queue';
  displayName?: string;
  userId?: string;
  waitCount?: number;
};

export type SessionMember = SeatMember | OutboundMember | QueueMember;

/**
 * 电话挂断原因
 * 0: 'voip 正常结束',
 * 1: '正常结束',
 * 2: '未接通',
 * 104: '用户挂断',
 * 105: '无座席可用',
 * 202: '被叫未接听',
 * 203: '被叫拒接挂断',
 * 204: '被叫关机',
 * 205: '被叫空号',
 * 206: '被叫占线',
 * 207: '被叫欠费',
 * 208: '外呼线路异常',
 * 209: '主叫取消',
 * 210: '被叫未接听',
 * 211: '客户端错误'
 * 212: '运营商拦截',
 */
export type PhoneEndStatus =
  | 0
  | 1
  | 2
  | 104
  | 105
  | 202
  | 203
  | 204
  | 205
  | 206
  | 207
  | 208
  | 209
  | 210
  | 211
  | 212
  | 'giveup';
export type HangupSide = 'user' | 'seat' | 'system';
export type CloseBy = 'user' | 'seat' | 'timer' | 'system';

export type SessionStatus = '100' | '150' | '200' | '400'; // 100呼叫中，150 等待对方接听, 200通话中，400结束会话
export type SessionType =
  | 'phone'
  | 'monitor'
  | 'dialBack'
  | 'internal'
  | 'im'
  | 'voip'
  | 'video'
  | 'audio'
  | 'ASRMonitor';
export type SessionState = 'ringing' | 'finished' | 'inProgress' | 'seatJoining';
export type ReceiveIvrStatus = '' | 'pending' | 'waiting' | 'error' | 'timeout' | 'ok';
export type ForwardInfo = {
  outlineStatus?: 'CALLING' | 'TALKING' | 'HANGUP' | 'FAILED';
  clientStatus?: 'HOLDING' | 'SERVING';
  forwardStartServerTime?: number;
  forwardOutlineNumber?: string;
};
export type MicStatus = 'on' | 'off';

type BaseSession = {
  sessionId: string;
  status: SessionStatus;
  type: SessionType;
  state?: SessionState;
  direction: Direction;
};
export type ServerType = 'staffSeat' | 'staffPhoneSeat' | 'miniProgramSeat' | 'staffExtensionSeat' | 'customer';
export type PeerSourceKey = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '10' | '11';
export type Session = BaseSession & {
  peerSource?: PeerSourceKey;

  // 电话
  protectedCallee?: string;
  protectedCaller?: string;
  calleePhoneNumber?: string;
  calleePhoneNumberText?: string;
  calleeLocation?: string;
  callerPhoneNumber?: string;
  callerPhoneNumberText?: string;
  callerLocation?: string;
  micStatus?: MicStatus; // 本地静音状态

  mute?: boolean; // 服务端静音状态，表示本地是否静音

  // 音频客服
  callee?: string;
  caller?: string;
  calleeRemark?: string;

  // 外线
  forwardInfo?: ForwardInfo;
  // ivr
  ivrPath?: { label?: string; key?: string }[];

  roomId?: string;
  ivrSoundPlaying?: boolean;
  receiveStatus?: ReceiveIvrStatus;
  remark?: string;
  holdStatus?: boolean; // 通话保持

  // phone
  aiEnabled?: boolean;

  // im: 由于im的sessionId每次不同，userId可能会相同，所以im sessionId用userId替代
  userId?: string;
  conversationID?: string; // GROUP + userId
  avatar?: string;
  count?: number;
  nickname?: string;
  staff?: {
    userId?: string;
  };
  onLineState?: boolean;
  lastMessage?: Message;
  clientData?: string | any;
  // 第一次接入的时候没有未读数，需要用标记展示小红点
  isFirstCallIn?: boolean;

  // video
  disableCamera?: boolean;

  mobile?: string;
  media?: {
    status: '100' | '150' | '200' | '400';
    type: 'video' | 'audio';
    startTime?: number;
    direction?: Direction;
  };

  // time
  timestamp?: number;
  duration?: number;
  timeout?: number;

  // other
  sdkCall?: boolean; // 是否sdk方式呼出
  isBlackUser?: boolean;
  channelName?: string;

  // 电话挂断状态
  hangupType?: PhoneEndStatus;
  hangupSide?: HangupSide;

  serverType?: ServerType;
  isHost?: boolean;
  members?: SessionMember[];

  isStartRealtimeAsr?: boolean;
};
export enum Direction {
  callIn,
  callOut,
}

export const isPhoneLikeType = (type: SessionType) => {
  const PhoneLikeType = ['phone', 'monitor', 'dialBack', 'internal', 'voip', 'ASRMonitor'];
  return PhoneLikeType.includes(type);
};
export const isIMLikeType = (type: SessionType) => {
  const IMLikeType = ['im'];
  return IMLikeType.includes(type);
};
