import { SEAT_TYPE_ENUM } from 'src/constants/manage';
import i18n from 'src/i18n';

const { t } = i18n;

export type SeatTypeRelatedFeatures = {
  // 显示logo
  showLogo: boolean;
  // 腾讯云呼叫中心
  tcccChineseName: string;
  // brand name
  brandName: string;
  // 呼叫中心
  tcccInstanceName: string;
  // 显示新手指引
  showBeginnerGuide: boolean;
  // 显示技术服务交流群
  showWxWorkSupportGroup: boolean;
  // 非座席角色登录，默认进入管理端
  defaultLoginToAdminPanelIfAdmin: boolean;
  // 电话权限
  phoneCallEnabled: boolean;
  // 在线会话权限
  imEnabled: boolean;
  // 视频客服权限
  videoCallEnabled: boolean;
  // 语音客服权限
  audioCallEnabled: boolean;
  // 实时语音识别功能
  realTimeAsr: boolean;
  // 自定义节假日
  customWorkday: boolean;
  // 显示警告
  lineUnavailableWarning: boolean;
  // IVR高级节点
  ivrAdvancedNode: boolean;
  // LoginComponent
  loginComponent?: React.ComponentType;
  // CRM权限
  crmEnabled: boolean;
};

const standSeatTypeRelatedFeatures: SeatTypeRelatedFeatures = {
  showLogo: true,
  tcccChineseName: t('腾讯云联络中心'),
  tcccInstanceName: t('企业联络中心'),
  brandName: t('腾讯云'),
  showBeginnerGuide: true,
  showWxWorkSupportGroup: true,
  defaultLoginToAdminPanelIfAdmin: false,
  phoneCallEnabled: true,
  imEnabled: true,
  videoCallEnabled: true,
  audioCallEnabled: true,
  realTimeAsr: false,
  customWorkday: false,
  lineUnavailableWarning: true,
  ivrAdvancedNode: false,
  crmEnabled: false,
};
// bug here
export const seatTypeToFeaturesMapping: Record<SEAT_TYPE_ENUM, SeatTypeRelatedFeatures> = {
  [SEAT_TYPE_ENUM.ADVANCED]: {
    ...standSeatTypeRelatedFeatures,
    realTimeAsr: true,
    customWorkday: true,
    ivrAdvancedNode: true,
    crmEnabled: true,
  },
  [SEAT_TYPE_ENUM.NORMAL]: standSeatTypeRelatedFeatures,
  [SEAT_TYPE_ENUM.IM_AGENT]: {
    ...standSeatTypeRelatedFeatures,
    phoneCallEnabled: false,
    videoCallEnabled: false,
    audioCallEnabled: false,
    lineUnavailableWarning: false,
  },
  [SEAT_TYPE_ENUM.IM_DESKKIT]: {
    ...standSeatTypeRelatedFeatures,
    brandName: t('腾讯云'),
    tcccChineseName: t('即时通讯IM-客服插件'),
    tcccInstanceName: t('客服插件'),
    showLogo: false,
    showWxWorkSupportGroup: false,
    defaultLoginToAdminPanelIfAdmin: true,
    phoneCallEnabled: false,
    videoCallEnabled: false,
    lineUnavailableWarning: false,
    audioCallEnabled: false,
  },
  [SEAT_TYPE_ENUM.NEW_STANDARD]: standSeatTypeRelatedFeatures,
  [SEAT_TYPE_ENUM.IVI_STANDARD]: standSeatTypeRelatedFeatures,
  [SEAT_TYPE_ENUM.IVI_PREMIUM]: {
    ...standSeatTypeRelatedFeatures,
    realTimeAsr: true,
    customWorkday: true,
    ivrAdvancedNode: true,
  },
};
