import { createAction, createAsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import * as t from 'io-ts';
import uniqBy from 'lodash/uniqBy';

import { getSession } from 'src/components/VerifyCode/captchaSessionMange';
import i18n from 'src/i18n';
import { request } from 'src/services/httpClient';
import { dialBack, startCall } from 'src/store/slices/sessions/pstn';
import { startCallInternal } from 'src/store/slices/sessions/rtc';
import sessionManage from 'src/utils/sessionManage';

import { captchaType } from '../../services/httpAPIs/login';

import { getUserSettings, loginInstance, updateUserSettings } from './app.thunk';
import { isPhoneLikeType } from './sessions';
import { addSession } from './sessions/sessions.action';
import { deleteSession, setActiveUser } from './sessions/sessions.thunk';

import { startInnerConversation } from './tim.thunk';

type MobileAcceptType = 0 | 1 | 2;

export type AssignConfig = {
  existCall: boolean;
  existIM: boolean;
  assignCall: boolean;
  assignIM: boolean;
};

export enum ForwardingType {
  Staff = 1,
  SkillGroup = 2,
  Extension = 3,
}

export enum ForwardingCondition {
  Always = 1,
  Conditional = 2,
}

export type ForwardingConfig = {
  enabled: boolean;
  condition: ForwardingCondition;
  target: {
    type: ForwardingType;
    staffUserId: string;
    skillGroupId: number;
    extension: string;
  };
};

export type UserInfo = {
  userId: string;
  sdkAppId: string;
  canUseMobile: boolean;
  useMobileCallOut: boolean;
  useMobileAcceptType: MobileAcceptType;
  isBindMobile: boolean;
  nickName: string;
  mobile: string;
  skillGroupId: string[];
  avatar: string;
  roleId: string;
  permissionList: string[];
  loginChecked: boolean;
  reason: string;
  currentUser: {
    sessionId: string;
    userId: string;
  };
  staffName: string;
  staffNo: string;
  isDemoAccount: boolean;
  skillGroupScope?: number[];
  multipleUserId: boolean;
  userIds: undefined | string[];
  postWhiteUrls: string[];
  checkBindMobile: boolean;
  hasAdmin: boolean;
  hasWorkbench: boolean;
  hasDeskAdmin: boolean;
} & AssignConfig;

const initialState: UserInfo = {
  userId: '',
  canUseMobile: false,
  useMobileCallOut: false,
  useMobileAcceptType: 0,
  assignCall: true,
  assignIM: true,
  existCall: true,
  existIM: true,
  sdkAppId: '',
  isBindMobile: false,
  nickName: '',
  mobile: '',
  skillGroupId: [] as string[],
  avatar: '',
  roleId: '',
  permissionList: [] as string[],
  loginChecked: false,
  reason: '',
  currentUser: {
    sessionId: '',
    userId: '',
  },
  staffName: '',
  staffNo: '',
  isDemoAccount: false,
  multipleUserId: false,
  userIds: undefined,
  postWhiteUrls: [],
  checkBindMobile: true,
  hasAdmin: false,
  hasDeskAdmin: false,
  hasWorkbench: false,
};

export const queryUserInstanceList = createAsyncThunk('queryUserInstanceList', async () => {
  const { userInstanceList } = await request('/tccclogin/login/queryUserInstanceList', {});
  return {
    userInstanceList,
  };
});

export const checkLogin = createAsyncThunk('checkLogin', async (arg, { rejectWithValue, dispatch }) => {
  try {
    const loginRes = await request('/tccclogin/login/checkLogin', {});
    if (loginRes.errorCode !== '0') {
      return rejectWithValue(i18n.t('checkLogin失败'));
    }
    if (!sessionManage.getGlobalUserId() && !sessionManage.getSessionKey()) {
      dispatch(queryUserInstanceList());
    }
    return {
      loginChecked: loginRes.errorCode === '0',
      isBindMobile: loginRes.isBindMobile,
      userId: sessionManage.getGlobalUserId() || loginRes.userId,
    };
  } catch (e) {
    return rejectWithValue(e);
  }
});
export const loginBypass = createAsyncThunk(
  'loginByPass',
  async ({ userid, passwd, captcha }: { userid: string; passwd: string; captcha: t.TypeOf<typeof captchaType> }) => {
    const res = await request('/tccclogin/login/loginByPass', {
      staff: {
        userid,
        passwd,
      },
      captcha,
    });
    return {
      loginChecked: true,
      isBindMobile: res.isBindMobile,
      userId: userid,
    };
  },
);

export const updateMobile = createAsyncThunk(
  'updateMobile',
  (
    { oldMobile, mobile, smsCaptchaCode }: { oldMobile: string; mobile: string; smsCaptchaCode: string },
    { getState },
  ) => {
    const { userId } = (getState() as any).userInfo;
    return request('/tccclogin/account/updateMobile', {
      oldMobile,
      mobile,
      userid: userId,
      isInstance: true,
      smsCaptcha: {
        module: 'changeMobile',
        sessionId: getSession(mobile),
        value: smsCaptchaCode,
      },
    });
  },
);

export const bindMobile = createAsyncThunk(
  'bindMobile',
  async ({ mobile, smsCaptchaCode }: { mobile: string; smsCaptchaCode: string }) =>
    request('/tccclogin/account/bindMobile', {
      mobile,
      captcha: {
        module: 'bindMobile',
        sessionId: getSession(mobile),
        value: smsCaptchaCode,
      },
      isInstance: true,
    }),
);

export const selectUserId = createAction<string>('selectUserId');
export const loginByCaptcha = createAsyncThunk(
  'loginByCaptcha',
  async ({ mobile, captchaCode }: { mobile: string; captchaCode: string }) => {
    let sessionId = '';
    const sessionValue = localStorage.getItem(`${mobile}sessionId`);
    if (sessionValue) {
      const data = JSON.parse(sessionValue);
      if (Date.now() - data.createTime < data.timeout * 1000) {
        sessionId = data.sessionId;
      }
    }

    await request('/tccclogin/login/loginByCaptcha', {
      mobile,
      captcha: {
        value: captchaCode,
        sessionId,
        module: 'login',
      },
    });
    return {
      loginChecked: true,
    };
  },
);

export const loginDemoByCaptcha = createAsyncThunk(
  'loginDemoByCaptcha',
  async (
    { mobile, code, sessionId }: { mobile: string; code: string; sessionId?: string },
    { dispatch, rejectWithValue },
  ) => {
    const token = await request('/tccclogin/demo/createSDKLoginTokenByCaptcha', {
      captcha: {
        module: 'loginDemo',
        sessionId: sessionId || getSession(mobile),
        value: code,
      },
      mobile,
    });
    localStorage.setItem('T3CDemoLoginMobile', mobile);
    localStorage.setItem('T3CDemoServiceNumber', token.ServingNum);
    localStorage.setItem('T3CDemoWebAppId', token.WebAppId);
    const sdkURL = new URL(token.SdkURL);
    const userid = sdkURL.searchParams.get('userid');
    const sdkAppId = sdkURL.searchParams.get('sdkAppId');
    if (userid && sdkAppId) {
      localStorage.setItem('T3CDemoSdkAppId', sdkAppId);
      // await dispatch(checkSDKLogin({ userid, sdkAppId, token: token.Token }));
      await dispatch(loginInstance({ sdkAppId, userId: userid, token: token.Token }));
      return {
        sdkAppId,
        loginChecked: true,
        isBindMobile: true,
        userId: userid,
      };
    }
    return rejectWithValue(i18n.t('Demo 登录失败，登录方法返回异常。[sdkURL={{0}}]', { 0: sdkURL }));
  },
);

function loginSuccess(
  state: Draft<UserInfo>,
  {
    payload,
  }: PayloadAction<{
    isBindMobile?: boolean;
    userId?: string;
  }>,
) {
  state.loginChecked = true;
  if (payload.userId) {
    state.userId = payload.userId;
  }
  if (typeof payload.isBindMobile === 'boolean') {
    state.isBindMobile = payload.isBindMobile;
  }
  if (payload.userId?.endsWith('@tcccdemo.com')) {
    state.isDemoAccount = true;
  }
}

export const getPostWhiteUrls = createAsyncThunk('getPostWhiteUrls', async () => {
  const res = await request('/tcccadmin/app/getCommKV', {
    key: 'thirdPartySystemSettings',
  });
  return {
    whiteList: JSON.parse(res?.value) ?? [],
  };
});

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    login(state, { payload: { isBindMobile } }: { payload: { isBindMobile: boolean } }) {
      state.loginChecked = true;
      state.isBindMobile = isBindMobile;
    },
    updateBindMobile(state, { payload }: { payload: boolean }) {
      state.isBindMobile = payload;
    },
    updateUseMobileConfig(
      state,
      {
        payload,
      }: {
        payload: Partial<{
          useMobileCallOut: boolean;
          useMobileAcceptType: MobileAcceptType;
        }>;
      },
    ) {
      if (payload.useMobileCallOut !== undefined) {
        state.useMobileCallOut = payload.useMobileCallOut;
      }
      if (payload.useMobileAcceptType !== undefined) {
        state.useMobileAcceptType = payload.useMobileAcceptType;
      }
    },
    updateCheckBindMobile(state, { payload }: { payload: boolean }) {
      state.checkBindMobile = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkLogin.fulfilled, (state, action) => {
        loginSuccess(state, action);
      })
      .addCase(loginBypass.fulfilled, loginSuccess)
      .addCase(loginDemoByCaptcha.fulfilled, loginSuccess)
      .addCase(loginByCaptcha.fulfilled, (state, { payload }) => {
        loginSuccess(state, {
          payload: {
            ...payload,
            isBindMobile: true,
          },
          type: loginByCaptcha.fulfilled.type,
        });
      })
      .addCase(queryUserInstanceList.fulfilled, (state, { payload: { userInstanceList } }) => {
        const userIds = uniqBy(userInstanceList, 'userId').map(({ userId }) => userId);
        state.multipleUserId = userIds.length !== 1;
        state.userIds = userIds;
        if (userIds.length !== 1) {
          state.userId = '';
        } else if (state.userId === '') {
          // eslint-disable-next-line prefer-destructuring
          state.userId = userIds[0];
        }
      })
      .addCase(selectUserId, (state, { payload }) => {
        state.userId = payload;
      })
      .addCase(loginInstance.fulfilled, (state, { payload }) => ({ ...state, ...payload.userInfo }))
      .addCase(setActiveUser.fulfilled, (state, { payload }) => {
        if (payload) {
          state.currentUser = {
            sessionId: payload.sessionId,
            userId: '',
          };
        }
      })
      .addCase(checkLogin.rejected, () => {
        sessionManage.loginOut();
      })
      .addCase(startCall.fulfilled, (state, action) => {
        state.currentUser.sessionId = action.payload.sessionId;
      })
      .addCase(dialBack.fulfilled, (state, { payload: { sessionId } }) => {
        state.currentUser.sessionId = sessionId;
      })
      .addCase(startCallInternal.fulfilled, (state, action) => {
        state.currentUser.sessionId = action.payload.sessionId;
      })
      .addCase(deleteSession.fulfilled, (state) => {
        state.currentUser = {
          sessionId: '',
          userId: '',
        };
      })
      .addCase(
        updateMobile.fulfilled,
        (
          state,
          {
            meta: {
              arg: { mobile },
            },
          },
        ) => {
          state.mobile = mobile?.startsWith('0086') ? mobile : `0086${mobile}`;
        },
      )
      .addCase(getUserSettings.fulfilled, (state, action) => {
        if (action.payload) {
          state.useMobileAcceptType = action.payload.useMobileAcceptType;
          state.useMobileCallOut = action.payload.useMobileCallOut;
          state.canUseMobile = action.payload.canUseMobile;
        }
      })
      .addCase(updateUserSettings.fulfilled, (state, action) => {
        if (action.payload) {
          state.useMobileAcceptType = action.payload.useMobileAcceptType;
          state.useMobileCallOut = action.payload.useMobileCallOut;
        }
      })
      .addCase(startInnerConversation.fulfilled, (state, action) => {
        if (action.payload.sessionId && action.payload.userId) {
          state.currentUser.sessionId = action.payload.sessionId;
          state.currentUser.userId = action.payload.userId;
        }
      })
      .addCase(addSession, (state, action) => {
        if (isPhoneLikeType(action.payload.type)) {
          state.currentUser.sessionId = action.payload.sessionId;
          if (action.payload.userId) {
            state.currentUser.userId = action.payload.userId;
          }
        }
      })
      .addCase(
        bindMobile.fulfilled,
        (
          state,
          {
            meta: {
              arg: { mobile },
            },
          },
        ) => {
          state.mobile = mobile?.startsWith('0086') ? mobile : `0086${mobile}`;
          state.isBindMobile = true;
        },
      )
      .addCase(getPostWhiteUrls.fulfilled, (state, action) => {
        state.postWhiteUrls = action.payload.whiteList;
      });
  },
});
export const userInfo = userInfoSlice.reducer;
export const { login, updateBindMobile, updateCheckBindMobile, updateUseMobileConfig } = userInfoSlice.actions;
