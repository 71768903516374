import { useCallback, useEffect, useMemo, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { Button, Form, message, Modal, Select, Switch } from '@tencent/tea-component';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { StyledFormItem, StyledRadio } from 'src/components/CentralizedComponents';
import { debugLogRequest } from 'src/services';
import { Extension } from 'src/services/httpAPIs/admin';
import { request } from 'src/services/httpClient';

import { RootState, useAppDispatch } from 'src/store';
import { updateUserSettings } from 'src/store/slices/app.thunk';
import { ForwardingType, ForwardingCondition } from 'src/store/slices/userInfo';
import { goldlog } from 'src/utils/goldlog';

import { ExtensionLogin } from './ExtensionLogin';

const LogInteraction = goldlog.of('SEAT').forType('INTERACTION');

const useStyles = makeStyles({
  switch: {
    margin: 0,
    transform: 'scale(0.8)',
  },
  switchItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const INITIAL_FORWARDING_CONFIG = {
  enabled: false,
  condition: ForwardingCondition.Always,
  target: {
    type: ForwardingType.Extension,
    extension: '',
    staffUserId: '',
    skillGroupId: 0,
  },
};

function SwitchItem(props: {
  value: boolean;
  label: string;
  tooltip?: string;
  disabled?: boolean;
  onChange: (value: boolean) => Promise<void>;
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const onChange = useMemo(
    () =>
      debounce(
        async (value: boolean) => {
          setLoading(true);
          await props.onChange(value);
          setLoading(false);
        },
        500,
        {
          leading: true,
          trailing: false,
        },
      ),
    [],
  );
  return (
    <div className={classes.switchItem}>
      <span>{props.label}</span>
      <Switch
        className={classes.switch}
        tooltip={props.tooltip}
        value={props.value}
        loading={loading}
        onChange={onChange}
        disabled={props.disabled}
      />
    </div>
  );
}

type UseMobileAcceptType = 0 | 1 | 2;
export function UseMobileAccept(props: { disabled: boolean }) {
  const dispatch = useAppDispatch();
  const useMobileAcceptType = useSelector((e: RootState) => e.userInfo.useMobileAcceptType);
  const value = useMobileAcceptType === 1 || useMobileAcceptType === 2;
  const { t } = useTranslation();
  const tooltipMap = new Map([
    [0, t('开启手机接听后，座席离线时来电将会呼叫到座席手机，如需开启手机接听功能请联系您的管理员添加号码至白名单')],
    [1, t('手机接听功能已开启，工作台离线时，来电将呼叫至座席手机')],
    [2, t('双呼功能开启时，来电将会同时呼叫工作台和座席手机')],
  ]);
  const onChange = async (value: UseMobileAcceptType) => {
    LogInteraction.doing('SWITCH_SWITCH').with({ target: value }, t('点击切换手机接听'));
    await dispatch(
      updateUserSettings({
        useMobileAcceptType: value,
      }),
    );
  };
  return (
    <>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
        <SwitchItem
          disabled={props.disabled}
          value={value}
          label={t('手机接听')}
          tooltip={tooltipMap.get(useMobileAcceptType) || ''}
          onChange={(value) => onChange(value ? 1 : (0 as UseMobileAcceptType))}
        />
        <div style={{ width: 26 }} />
      </div>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
        {value && (
          <>
            <select
              onChange={(e) => onChange(+e.target.value as UseMobileAcceptType)}
              value={useMobileAcceptType}
              style={{ fontSize: 12, border: 'none', alignSelf: 'flex-start', width: '100%', padding: '0.5em 0.3em' }}
            >
              <option title={tooltipMap.get(1)} value={1}>
                {t('仅工作台离线时')}
              </option>
              <option title={tooltipMap.get(2)} value={2}>
                {t('始终')}
              </option>
            </select>
          </>
        )}
        <div style={{ width: 26 }} />
      </div>
    </>
  );
}

export function UseMobileCallOut(props: { disabled: boolean }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const useMobileCallOut = useSelector((e: RootState) => e.userInfo.useMobileCallOut);

  const onChange = async (value: boolean) => {
    LogInteraction.doing('SWITCH_SWITCH').with({ target: value }, t('点击切换手机外呼'));
    await dispatch(
      updateUserSettings({
        useMobileCallOut: value,
      }),
    );
  };
  return (
    <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
      <SwitchItem
        disabled={props.disabled}
        value={useMobileCallOut}
        label={t('手机外呼')}
        tooltip={
          useMobileCallOut
            ? t('手机外呼功能已开启，在工作台外呼时会转接至手机发起外呼')
            : t('如需开启手机外呼功能，请联系您的管理员添加号码至白名单')
        }
        onChange={onChange}
      />
      <div style={{ width: 26 }} />
    </div>
  );
}

export function UseExtensionCall() {
  const [flag, setFlag] = useState(false);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const handleChange = async (value: boolean) => {
    if (!value) {
      setFlag(false);
      return;
    }
    setVisible(true);
  };

  const handleRefresh = () => {
    setFlag(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <>
      <SwitchItem value={flag} label={t('话机呼入/呼出')} onChange={handleChange} />
      <ExtensionLogin visible={visible} onClose={handleClose} onRefresh={handleRefresh} />
    </>
  );
}

export function UseForwardingConfig() {
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [condition, setCondition] = useState(ForwardingCondition.Always);
  const [type, setType] = useState(ForwardingType.Extension);
  const [skillGroupId, setSkillGroupId] = useState(0);
  const [staffUserId, setStaffUserId] = useState('');
  const [extension, setExtension] = useState('');
  const [staffOptions, setStaffOptions] = useState<Option[]>([]);
  const [skillGroupOptions, setSkillGroupOptions] = useState<Option[]>([]);
  const [extensionOptions, setExtensionOptions] = useState<Option[]>([]);
  const [errorType, setErrorType] = useState<'Extension' | 'Staff' | 'SkillGroup' | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [staffSearchOptions, setStaffSearchOptions] = useState({
    pageNum: 0,
    fuzzingKeyWord: '',
  });
  const [extensionSearchOptions, setExtensionSearchOptions] = useState({
    pageNum: 0,
    fuzzingKeyWord: '',
  });
  const [staffTotal, setStaffTotal] = useState(0);
  const [extensionTotal, setExtensionTotal] = useState(0);

  const TypeMap = {
    [ForwardingType.Staff]: t('Email'),
    [ForwardingType.SkillGroup]: t('技能组ID'),
    [ForwardingType.Extension]: t('分机号'),
  };
  type Option = {
    value: string;
    text: string;
  };

  const getSkillGroup = useCallback(
    (fuzzingKeyWord?: string): Promise<SkillGroup[]> =>
      request('/tcccadmin/tech/getSkillGroupList', { fuzzingKeyWord }).then(({ skillGroupList }) => skillGroupList),
    [],
  );

  const getStaffList = useCallback(
    (fuzzingKeyWord?: string, staffPageNum?: number): Promise<{ staffList: Staff[]; total: number }> =>
      request('/tcccadmin/staff/getStaffList', {
        fuzzingKeyWord,
        pageSize: 50,
        pageNum: staffPageNum,
      }).then(({ staffList, total }) => ({ staffList, total })),
    [],
  );

  const getExtensionList = useCallback(
    (fuzzingKeyWord?: string, extensionPageNum?: number): Promise<{ extensionList: Extension[]; total: number }> =>
      request('/tcccadmin/extension/getList', {
        fuzzingKeyWord,
        pageSize: 50,
        pageNum: extensionPageNum,
      }).then(({ extensionList, total }) => ({ extensionList, total })),
    [],
  );

  const handleSave = async () => {
    if (type === ForwardingType.Extension && !extension) {
      setErrorType('Extension');
      setErrorMsg(t('请选择话机'));
      return;
    }
    if (type === ForwardingType.Staff && !staffUserId) {
      setErrorType('Staff');
      setErrorMsg(t('请选择座席'));
      return;
    }
    if (type === ForwardingType.SkillGroup && !skillGroupId) {
      setErrorType('SkillGroup');
      setErrorMsg(t('请选择技能组'));
      return;
    }
    setOpen(false);
    const targetFormConfig = {
      staffUserId: '',
      skillGroupId: 0,
      extension: '',
    };
    if (type === ForwardingType.Extension) {
      targetFormConfig.extension = extension;
    } else if (type === ForwardingType.Staff) {
      targetFormConfig.staffUserId = staffUserId;
    } else {
      targetFormConfig.skillGroupId = skillGroupId;
    }
    const forwardingConfig = {
      enabled,
      condition,
      target: {
        type,
        ...targetFormConfig,
      },
    };
    try {
      await request('/tcccadmin/staff/setConfig', { forwardingConfig });
      message.success({ content: t('修改成功，修改将于一分钟后生效') });
    } catch (e: any) {
      debugLogRequest('setConfig error', e.message);
    }
  };

  const clearFormError = () => {
    setErrorType(null);
    setErrorMsg(null);
  };

  const handleToggleEnableTransfer = async (val: boolean) => {
    setEnabled(val);
    if (val) {
      setOpen(true);
    } else {
      try {
        await request('/tcccadmin/staff/setConfig', { forwardingConfig: INITIAL_FORWARDING_CONFIG });
        message.success({ content: t('修改成功，修改将于一分钟后生效') });
      } catch (e: any) {
        debugLogRequest('setConfig error', e.message);
      }
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (staffOptions.length >= staffTotal && staffSearchOptions.pageNum > 0) {
      return;
    }
    getStaffList(staffSearchOptions.fuzzingKeyWord, staffSearchOptions.pageNum).then(({ staffList }) => {
      if (staffSearchOptions.pageNum === 0) {
        setStaffOptions(
          staffList.map((item) => ({
            value: item.userId,
            text: `${item.staffName} (${item.userId})`,
          })),
        );
        return;
      }
      setStaffOptions(
        staffOptions.concat(
          staffList.map((item) => ({
            value: item.userId,
            text: `${item.staffName} (${item.userId})`,
          })),
        ),
      );
    });
  }, [staffSearchOptions]);

  useEffect(() => {
    if (extensionOptions.length >= extensionTotal && extensionSearchOptions.pageNum > 0) {
      return;
    }
    getExtensionList(extensionSearchOptions.fuzzingKeyWord, extensionSearchOptions.pageNum).then(
      ({ extensionList }) => {
        if (extensionSearchOptions.pageNum === 0) {
          setExtensionOptions(
            extensionList.map((item) => ({
              value: item.fullExtensionId,
              text: `${item.extensionName} (${item.extensionId})`,
            })),
          );
          return;
        }
        setExtensionOptions(
          extensionOptions.concat(
            extensionList.map((item) => ({
              value: item.fullExtensionId,
              text: `${item.extensionName} (${item.extensionId})`,
            })),
          ),
        );
      },
    );
  }, [extensionSearchOptions]);

  useEffect(() => {
    // 获取初始数据
    getSkillGroup().then((list) => {
      setSkillGroupOptions(
        list.map((item) => ({
          value: item.skillGroupId.toString(),
          text: `${item.skillGroupName} (${item.skillGroupId})`,
        })),
      );
    });
    getStaffList().then((list) => {
      setStaffOptions(
        list.staffList.map((item) => ({
          value: item.userId,
          text: `${item.staffName} (${item.userId})`,
        })),
      );
      setStaffTotal(list.total);
    });
    getExtensionList().then((list) => {
      setExtensionOptions(
        list.extensionList.map((item) => ({
          value: item.fullExtensionId,
          text: `${item.extensionName} (${item.extensionId})`,
        })),
      );
      setExtensionTotal(list.total);
    });
    request('/tcccadmin/staff/getConfig', {}).then(({ forwardingConfig }) => {
      setEnabled(forwardingConfig.enabled);
      if (!forwardingConfig.enabled) {
        setCondition(ForwardingCondition.Always);
        setType(ForwardingType.Extension);
        setExtension('');
        setStaffUserId('');
        setSkillGroupId(0);
        return;
      }
      if (forwardingConfig.condition === 0) {
        setCondition(ForwardingCondition.Always);
      } else {
        setCondition(forwardingConfig.condition as ForwardingCondition);
      }
      setType(forwardingConfig.target.type as ForwardingType);
      setExtension(forwardingConfig.target.extension);
      setStaffUserId(forwardingConfig.target.staffUserId);
      setSkillGroupId(forwardingConfig.target.skillGroupId);
    });
  }, []);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <SwitchItem value={enabled} label={t('呼叫转移')} onChange={(val) => handleToggleEnableTransfer(val)} />
        {enabled ? (
          <Button style={{ minWidth: 22 }} type="link" onClick={() => setOpen(true)}>
            {'>'}
          </Button>
        ) : (
          <div style={{ minWidth: 22, visibility: 'hidden' }} />
        )}
      </div>
      <Modal caption={t('呼叫转移')} visible={open} size={540} onClose={() => {}}>
        <Modal.Body>
          <Form layout="fixed" fixedLabelWidth={72}>
            <StyledFormItem align="middle" label={t('转移条件')}>
              <StyledRadio.Group onChange={(value) => setCondition(Number(value))} value={condition.toString()}>
                <StyledRadio name="1">{t('无条件')}</StyledRadio>
                <StyledRadio name="2">{t('有条件')}</StyledRadio>
              </StyledRadio.Group>
            </StyledFormItem>
            <StyledFormItem align="middle" label={t('转移对象')}>
              <StyledRadio.Group
                onChange={(value) => {
                  setType(Number(value));
                  clearFormError();
                }}
                value={type.toString()}
              >
                <StyledRadio name={ForwardingType.Extension.toString()}>{t('话机')}</StyledRadio>
                <StyledRadio name={ForwardingType.Staff.toString()}>{t('座席')}</StyledRadio>
                <StyledRadio name={ForwardingType.SkillGroup.toString()}>{t('技能组')}</StyledRadio>
              </StyledRadio.Group>
            </StyledFormItem>
            <StyledFormItem
              align="middle"
              required
              label={TypeMap[type as keyof typeof TypeMap]}
              status={errorType ? 'error' : undefined}
              message={errorMsg || undefined}
            >
              {type === ForwardingType.Extension && (
                <Select
                  searchable
                  virtual
                  clearable
                  matchButtonWidth
                  style={{ width: 260 }}
                  placeholder={t('请选择话机')}
                  options={extensionOptions}
                  appearance="button"
                  value={extension}
                  onSearch={(value) => {
                    setExtensionSearchOptions({
                      pageNum: 0,
                      fuzzingKeyWord: value || '',
                    });
                  }}
                  onScrollBottom={() => {
                    setExtensionSearchOptions({
                      ...extensionSearchOptions,
                      pageNum: extensionSearchOptions.pageNum + 1,
                    });
                  }}
                  onChange={(value) => {
                    setExtension(value);
                    clearFormError();
                  }}
                />
              )}
              {type === ForwardingType.Staff && (
                <Select
                  searchable
                  virtual
                  clearable
                  matchButtonWidth
                  style={{ width: 260 }}
                  placeholder={t('请选择座席')}
                  options={staffOptions}
                  appearance="button"
                  value={staffUserId}
                  onSearch={(value) => {
                    setStaffSearchOptions({
                      pageNum: 0,
                      fuzzingKeyWord: value || '',
                    });
                  }}
                  onScrollBottom={() => {
                    setStaffSearchOptions({
                      ...staffSearchOptions,
                      pageNum: staffSearchOptions.pageNum + 1,
                    });
                  }}
                  onChange={(value) => {
                    setStaffUserId(value);
                    clearFormError();
                  }}
                />
              )}
              {type === ForwardingType.SkillGroup && (
                <Select
                  searchable
                  clearable
                  virtual
                  matchButtonWidth
                  style={{ width: 260 }}
                  placeholder={t('请选择技能组')}
                  options={skillGroupOptions}
                  appearance="button"
                  value={skillGroupId.toString()}
                  onChange={(value) => {
                    setSkillGroupId(Number(value));
                    clearFormError();
                  }}
                />
              )}
            </StyledFormItem>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type={'primary'} onClick={handleSave}>
            {t('保存')}
          </Button>
          <Button type={'weak'} onClick={() => setOpen(false)}>
            {t('取消')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
