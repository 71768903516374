import { makeStyles } from '@material-ui/core';
const getStyles = makeStyles(() => ({
  collapse: {
    background: '#fefefe',
  },
  list: {
    background: '#fff',
    padding: 0,
    border: '1px solid #f2f2f2',
  },
  secondaryAction: {
    width: 226,
    right: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rename: {
    background: '#fff',
    marginRight: 10,
  },
  actionIcon: {
    marginRight: 14,
  },
  listItemGroupName: {
    fontSize: 12,
    color: '#444',
    fontFamily: 'PingFangSC-Medium !important',
    maxWidth: 240,
  },
  addDialogTextField: {
    height: 110,
  },
  addDialogInput: {
    width: 280,
  },
  dialogPaper: {
    width: 470,
  },
  dataError: {
    color: 'red',
    fontSize: '12px',
    margin: 0,
    height: 17,
  },
  operationIcon: {
    minWidth: '24px !important',
    marginRight: 6,
    marginLeft: 6,
  },
  groupItem: {
    borderBottom: '1px solid #F2F2F2',
  },
  quickReplyWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  quickReplyContent: {
    maxWidth: '400px',
    height: 17,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  quickReplyEdit: {
    width: 15,
    height: 15,
    marginLeft: 'auto',
    cursor: 'pointer',
  },
  quickTableHeader: {
    padding: '20px 0',
    display: 'flex',
    alignItems: 'center',
  },
  searchInputIcon: {
    marginLeft: 6,
    color: '#888',
    fontSize: 22,
  },
  searchInputRoot: {
    border: '1px solid #ddd',
    background: '#fff',
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 15,
  },
  quickTableBox: {
    padding: 0,
    border: 0,
    boxShadow: 'none',
  },
  tableOperation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 90,
    margin: '14px 50px 14px 14px',
  },
  btnWrapper: {
    width: 72,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  quickReplyTitle: {
    flexShrink: 1,
    flexGrow: 0,
    maxWidth: 70,
    minWidth: 70,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    margin: '14px 15px 14px 36px',
  },
  groupNameWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  createReply: {
    borderRadius: 15,
    margin: '0 10px',
  },
  listContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '400px',
  },
}));

export default getStyles;
