import { useState } from 'react';

import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { ArrowDropDown, ArrowRight, Check } from '@material-ui/icons';
import { Modal } from '@tencent/tea-component';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import { i18nLanguage, statusChecked, statusVisiable, UserStatusMap, UserStatusType } from 'tccc-utils';

import { ReactComponent as NoiseReductionIcon } from 'src/assets/images/ai-noise-reduction.svg';
import { MyProfile } from 'src/components/MyProfile/MyProfile';
import { UnderFeatureToggle } from 'src/components/UnderFeatureToggle/UnderFeatureToggle';
import { sendMessageToIframe } from 'src/utils/communicate';
import { goldlog } from 'src/utils/goldlog';

import { isDev, isInternational, leadDebounce } from '../../../utils';
import { useAgentTopBarState } from '../AgentTopBar';
import { NoiseReductionProps } from '../NoiseReduction';

import { useStyles } from './AccountSettings.style';
import { ChangePasswordDialog } from './ChangePasswordDialog';
import { ExtensionInfo } from './ExtensionInfo';
import { UseForwardingConfig, UseMobileAccept, UseMobileCallOut } from './SwitchItem';

const LogInteraction = goldlog.of('SEAT').forType('INTERACTION');
const useListItemText = makeStyles({
  primary: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
  },
});
const useListItemIcon = makeStyles({
  root: {
    minWidth: 10,
    marginRight: 4,
  },
});
function StatusItem(props: {
  restReasons?: any[];
  onClick?: any;
  onClickRest?: any;
  reason?: any;
  checked?: any;
  openReasons?: any;
  disabled?: boolean;
  visible?: boolean;
  text?: string;
  color?: string;
}) {
  const classes = useStyles();
  const listItemTextClasses = useListItemText();
  const listItemIconCLasses = useListItemIcon();
  if (!props.visible) {
    return null;
  }
  const checkWrapperClass = props.restReasons?.length ? classes.listItemActionLeft : classes.listItemAction;
  const isDisableClick = props.disabled === undefined ? props.checked : props.disabled;
  return (
    <>
      <ListItem
        className={classes.wrapperItem}
        button
        disableGutters
        dense
        disabled={isDisableClick}
        onClick={props.onClick}
      >
        <ListItemIcon classes={listItemIconCLasses}>
          <div className={classes.statusDot} style={{ background: props.color }} />
        </ListItemIcon>
        <ListItemText classes={listItemTextClasses} primary={props.text} />
        {props.checked && !props.reason && (
          <ListItemSecondaryAction className={checkWrapperClass}>
            <Check className={classes.checkIcon} />
          </ListItemSecondaryAction>
        )}
        {!!props.restReasons?.length && (
          <ListItemSecondaryAction className={classes.listItemAction}>
            {props.openReasons ? (
              <ArrowDropDown className={classes.arrowIcon} />
            ) : (
              <ArrowRight className={classes.arrowIcon} />
            )}
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {!!props.restReasons?.length && (
        <Collapse in={props.openReasons} timeout="auto">
          <List component="div" disablePadding>
            {props.restReasons.map((record) => (
              <ListItem
                button
                disableGutters
                dense
                key={record.name}
                disabled={record.name === props.reason && props.checked}
                className={classes.nested}
                onClick={() => {
                  props.onClickRest(record.name);
                }}
              >
                <ListItemText classes={listItemTextClasses} primary={record.name} />
                {record.name === props.reason && props.checked && (
                  <ListItemSecondaryAction className={classes.listItemAction}>
                    <Check className={classes.checkIcon} />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

export function AccountSettings({
  fromSdk,
  isNoiseReductionEnabled,
  isAiNoiseReductionInWhiteList,
  turnOffNoiseReduction,
  turnOnNoiseReduction,
  open: accountSettingOpen,
}: {
  fromSdk?: boolean;
  open?: boolean;
} & NoiseReductionProps) {
  const classes = useStyles();
  const listItemTextClasses = useListItemText();
  const {
    status,
    connectionState,
    nextStatus,
    logicStatus,
    reason,
    restReasonList,
    setRest,
    setReady,
    setDisplayBusy,
    onHandleOffline,
    onHandleOnline,
    onHandleExit,
    onHandleSwitch,
    nickName,
    canUseMobile,
  } = useAgentTopBarState();
  const [open, setOpen] = useState(true);
  const currentLanguage = i18nLanguage.language;
  const [profileOpen, setProfileOpen] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const { from } = queryString.parse(window.location.search);
  const isFromExperienceCenter = from === 'exp';
  const { t } = useTranslation();

  const handleConfirmOffline = (exitFn: any) => async () => {
    if (status === UserStatusMap.busy) {
      const yes = await Modal.confirm({
        message: t('当前正在服务客户，确认下线？'),
        description: t('下线后接收不到客户的消息，重新上线后恢复连接。'),
        okText: t('确认'),
        cancelText: t('取消'),
      });
      if (yes) {
        exitFn();
      }
      return;
    }
    exitFn();
  };

  const handleToggleRest = leadDebounce(() => {
    if (restReasonList?.length) {
      setOpen(!open);
    } else {
      setRest(status);
    }
  }, 300);

  const handleToggleRestReason = leadDebounce((reason: string) => {
    setRest(reason);
  }, 300);

  const handleToggleDisplayBusy = leadDebounce(() => {
    setDisplayBusy();
  }, 300);

  const handleToggleIdle = leadDebounce(() => setReady());

  const handleToggleOnline = leadDebounce(onHandleOnline, 300);

  const showStatus = (targetStatus: UserStatusType) =>
    statusVisiable(targetStatus, {
      status,
      logicStatus,
    });

  const showStatusChecked = (targetStatus: UserStatusType) =>
    statusChecked(targetStatus, {
      status,
      logicStatus,
      nextStatus,
    });

  const handleCheckDeviceOnClick = () => {
    LogInteraction.doing('CLICK_JUMP').with(t('跳转设备检测'));
    window.open('/helper');
  };

  const handleSwitchLanguage = () => {
    const language = currentLanguage === 'zh' ? 'en' : 'zh';
    i18nLanguage.setLanguage(language, true);
    window.location.reload();
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.nickName}>{t('客服：{{0}}', { 0: nickName })}</div>
      <MyProfile open={profileOpen} setOpen={setProfileOpen} />
      <ChangePasswordDialog open={changePasswordOpen} onClose={() => setChangePasswordOpen(false)} />
      <List className={classes.wrapperItem}>
        {connectionState === 'connected' ? (
          <>
            <StatusItem
              text={t('示闲')}
              visible
              color={'#3FCFB8'}
              checked={status === UserStatusMap.busy ? logicStatus === 'ready' : status === UserStatusMap.free}
              onClick={handleToggleIdle}
            />
            <StatusItem
              text={t('示忙')}
              color={'#FC4F05'}
              visible
              checked={status === UserStatusMap.busy ? logicStatus === 'notReady' : status === UserStatusMap.notReady}
              onClick={handleToggleDisplayBusy}
            />
            <StatusItem
              text={t('话后整理')}
              visible={status === UserStatusMap.countdown || status === UserStatusMap.arrange}
              color={'#006EFF'}
              checked={status === UserStatusMap.countdown || status === UserStatusMap.arrange}
            />
            <StatusItem
              text={t('小休')}
              visible
              checked={status === UserStatusMap.busy ? logicStatus === 'rest' : status === UserStatusMap.rest}
              color={'#006EFF'}
              onClick={handleToggleRest}
              onClickRest={handleToggleRestReason}
              restReasons={restReasonList}
              reason={reason}
              openReasons={open}
            />
            <StatusItem
              text={t('下线')}
              visible={showStatus(UserStatusMap.offline)}
              checked={showStatusChecked(UserStatusMap.offline)}
              color={'#999'}
              onClick={handleConfirmOffline(onHandleOffline)}
            />
          </>
        ) : (
          <>
            <StatusItem
              visible
              text={UserStatusMap.offline === status ? t('上线') : t('立即重连')}
              color={'#3FCFB8'}
              onClick={handleToggleOnline}
            />
            <StatusItem
              text={t('下线')}
              visible={showStatus(UserStatusMap.offline)}
              checked={showStatusChecked(UserStatusMap.offline)}
              color={'#999'}
              onClick={handleConfirmOffline(onHandleOffline)}
            />
          </>
        )}
      </List>
      <UnderFeatureToggle featureToggle={{ phoneCallEnabled: true, videoCallEnabled: true, audioCallEnabled: true }}>
        <>
          <ExtensionInfo open={!!accountSettingOpen} />
          {!isInternational() && (
            <div className={classes.phoneSwitch}>
              <UseMobileAccept disabled={!canUseMobile} />
              <UseMobileCallOut disabled={!canUseMobile} />
            </div>
          )}
          <UseForwardingConfig />
          <hr style={{ margin: '4px 0', borderBottom: '1px solid #F7F7F7' }} />
          {isAiNoiseReductionInWhiteList &&
            (isNoiseReductionEnabled ? (
              <ListItem className={classes.wrapperItem} button disableGutters dense onClick={turnOffNoiseReduction()}>
                <ListItemIcon style={{ color: '#27c565', minWidth: 20 }}>
                  <NoiseReductionIcon />
                </ListItemIcon>
                <ListItemText classes={listItemTextClasses} primary={t('退出背景音抑制')}></ListItemText>
              </ListItem>
            ) : (
              <ListItem className={classes.wrapperItem} button disableGutters dense onClick={turnOnNoiseReduction()}>
                <ListItemIcon style={{ minWidth: 20 }}>
                  <NoiseReductionIcon />
                </ListItemIcon>
                <ListItemText classes={listItemTextClasses} primary={t('开启背景音抑制')}></ListItemText>
              </ListItem>
            ))}
        </>
      </UnderFeatureToggle>
      <List className={classes.wrapperItem}>
        <ListItem className={classes.wrapperItem} button disableGutters dense>
          <ListItemText
            onClick={() => setProfileOpen(true)}
            classes={listItemTextClasses}
            primary={t('个人信息')}
          ></ListItemText>
        </ListItem>
        <ListItem className={classes.wrapperItem} button disableGutters dense>
          <ListItemText
            onClick={() => setChangePasswordOpen(true)}
            classes={listItemTextClasses}
            primary={t('修改密码')}
          ></ListItemText>
        </ListItem>
        {isDev() && !fromSdk && !isFromExperienceCenter && (
          <ListItem
            className={classes.wrapperItem}
            button
            disableGutters
            dense
            disabled={status === UserStatusMap.busy}
          >
            <ListItemText
              classes={listItemTextClasses}
              primary={t('切换应用')}
              onClick={leadDebounce(onHandleSwitch, 300)}
            />
          </ListItem>
        )}
        {!fromSdk && (
          <ListItem className={classes.wrapperItem} button disableGutters dense>
            <ListItemText
              onClick={handleSwitchLanguage}
              classes={listItemTextClasses}
              primary={t(`切换为{{language}}`, { language: currentLanguage === 'zh' ? t('英文') : t('中文') })}
            ></ListItemText>
          </ListItem>
        )}
        {!isFromExperienceCenter && (
          <ListItem className={classes.wrapperItem} button disableGutters dense>
            <ListItemText classes={listItemTextClasses} primary={t('设备检测')} onClick={handleCheckDeviceOnClick} />
          </ListItem>
        )}
        {!fromSdk && (
          <>
            <ListItem className={classes.wrapperItem} button disableGutters dense></ListItem>
            <ListItem className={classes.wrapperItem} button disableGutters dense>
              <ListItemText
                classes={listItemTextClasses}
                primary={t('退出登录')}
                onClick={async () => {
                  if (UserStatusMap.busy === status) {
                    const yes = await Modal.confirm({
                      message: t('当前正在服务客户，确认下线？'),
                      description: t('下线后接收不到客户的消息，重新上线后恢复连接。'),
                      okText: t('确认'),
                      cancelText: t('取消'),
                    });
                    if (yes) {
                      onHandleExit();
                    }
                    return;
                  }
                  if (isFromExperienceCenter) {
                    sendMessageToIframe({
                      type: 'offline',
                    });
                    localStorage.clear();
                  }
                  onHandleExit();
                }}
              />
            </ListItem>
          </>
        )}
      </List>
    </div>
  );
}
