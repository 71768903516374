/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useState, Fragment, useMemo } from 'react';

import { Button, Paper, Box, Tooltip, TextField } from '@material-ui/core';

import Collapse from '@material-ui/core/Collapse';
import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ExpandLess, ExpandMore, Search } from '@material-ui/icons';
import uniqBy from 'lodash/uniqBy';
import { useTranslation } from 'react-i18next';
import { ulid } from 'ulid';

import BaseDialog from 'src/components/BaseDialog/BaseDialog';

import DSCircularProgress from 'src/components/DSCircularProgress/DSCircularProgress';
import DSSelect from 'src/components/DSFormControls/DSSelect/DSSelect';
import DSTable from 'src/components/DSTable/DSTable';
import { useRequest } from 'src/services';
import { pick, showError } from 'src/utils';
import globalEventEmitter from 'src/utils/globalEventEmitter';

import { goldlog } from 'src/utils/goldlog';

import { modifyData } from './modifyData';

import useStyles from './QuickReply.style';
const LogInteraction = goldlog.of('MANAGE').forType('INTERACTION');

function QuickReply() {
  const classes = useStyles();
  const { t } = useTranslation();
  // 表格快捷回复数据
  const [tableData, setTableData] = useState([]);
  // 添加快捷回复对话框
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dataError, setDataError] = useState({});
  // 新建数据
  const [newData, setNewData] = useState({
    content: '',
    title: '',
    group: '',
    id: '',
  });
  const [data, setData] = useState({});
  const [groups, setGroups] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  // 修改数据
  const [commKVMessageState, setCommKVMessageState] = useState('');
  const [commKVErrorState, setCommKVErrorState] = useState(false);
  const [openIndex, setOpenIndex] = useState([0]);
  const [loadingIndex, setLoadingIndex] = useState(-1);
  const [addGroupName, setAddGroupName] = useState(false);
  const [changeGroupNameIndex, setChangeGroupNameIndex] = useState(-1);

  const listHead = {
    title: {
      title: t('标题'),
    },
    content: {
      title: t('回复内容'),
    },
    operation: {
      title: t('操作'),
    },
  };
  // 表格头
  const tableHead = {
    content: {
      title: '',
    },
  };
  const changeData = (key, event) => {
    if (key === 'group' && typeof event === 'string') {
      return setNewData((d) => ({
        ...d,
        group: event,
      }));
    }

    let value = event;
    if (typeof event === 'object') {
      value = event?.target?.value;
      setNewData((d) => ({
        ...d,
        [key]: value,
      }));
    }
  };
  // 读ckv
  // 数据格式
  const [commKVCanceller, { message: commKVMessage }, commKVError, commKVLoading] = useRequest(
    '/tcccadmin/quickreply/queryAvailableQuickReplyList',
    {},
    {
      complete: (value) => {
        let formateData = [];
        try {
          const data = value?.groupQuickReplyList ?? [];
          data.map((item) => {
            item?.quickReplyList?.map((innerItem) => {
              formateData.push({ ...innerItem, groupName: item.groupName });
            });
          });
        } catch (e) {
          formateData = [];
        }
        try {
          setTableData(
            formateData.map((item) => {
              item.id = item.quickReplyId || ulid();
              item.group = item.groupName || t('未知分组');
              item.title = item.title || t('未知标题');
              return item;
            }),
          );
          const groups = uniqBy(formateData, 'group').map((e) => e.group);
          setGroups(groups);
        } catch (e) {
          setCommKVMessageState(commKVMessage);
          setCommKVErrorState(commKVError);
        }
      },
    },
  );
  const parsedTableData = useCallback(
    (data) =>
      data?.map((item) =>
        pick(item, Object.keys(tableHead), (value, key) => {
          if (key === 'content') {
            // 换行
            return (
              <div className={classes.listContent}>
                <Tooltip arrow placement={'bottom-start'} title={item.title}>
                  <div className={classes.quickReplyTitle}>{item.title}</div>
                </Tooltip>
                <Tooltip placement={'bottom-start'} title={item.content}>
                  <div className={classes.quickReplyContent}>{item.content}</div>
                </Tooltip>
                <div className={classes.tableOperation}>
                  <Button
                    onClick={() => {
                      globalEventEmitter.emit('im-quick-reply', item.content);
                    }}
                  >
                    {t('使用')}
                  </Button>
                </div>
              </div>
            );
          }
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableData],
  );

  const onHandleSearchChange = (e) => {
    const { value } = e.target;
    LogInteraction.doing('CLICK_SEARCH').with(t('点击搜索快捷回复'));
    setSearchVal(value);
  };

  useEffect(
    () => () => {
      commKVCanceller();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    let searchTableData = tableData;
    if (searchVal !== '') {
      searchTableData = tableData.filter(
        (data) => data.title.indexOf(searchVal) !== -1 || data.content.indexOf(searchVal) !== -1,
      );
    }
    const data = searchTableData.reduce((pre, item) => {
      if (!pre[item.group]) pre[item.group] = [];
      pre[item.group].push(item);
      return pre;
    }, {});
    setData(data);
  }, [tableData, searchVal]);
  const groupsAfterSearch = useMemo(() => Object.keys(data), [data]);
  return (
    <Fragment>
      <Box className={classes.quickTableHeader}>
        <InputBase
          startAdornment={<Search className={classes.searchInputIcon} />}
          placeholder={t('搜索快捷回复')}
          classes={{
            root: classes.searchInputRoot,
          }}
          value={searchVal}
          onChange={onHandleSearchChange}
        />
      </Box>

      <Box p={0}>
        <Paper p={0} square={true} elevation={0} className={classes.quickTableBox}>
          <List component="nav" aria-labelledby="nested-list-subheader" className={classes.list} disablePadding>
            <DSTable
              showTableBody={groupsAfterSearch.length === 0}
              tableHead={listHead}
              loading={commKVLoading}
              emptyText={t('无相关结果，请更换关键词搜索')}
              cellProps={{
                operation: {
                  padding: 'none',
                },
              }}
            />
            {groupsAfterSearch.map((groupName, index) => (
              <div key={groupName} className={classes.groupItem}>
                <ListItem
                  disableGutters
                  button
                  onClick={() => {
                    if (openIndex.includes(index)) {
                      setOpenIndex(openIndex.filter((item) => item !== index));
                    } else {
                      openIndex.push(index);
                      setOpenIndex([...openIndex]);
                    }
                  }}
                >
                  <ListItemIcon className={classes.operationIcon}>
                    {openIndex.includes(index) ? <ExpandLess /> : <ExpandMore />}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <div className={classes.groupNameWrapper}>
                        {index === changeGroupNameIndex ? (
                          <>
                            <InputBase
                              value={newData.group}
                              onInput={changeData.bind(this, 'group')}
                              autoFocus={true}
                              onKeyDown={function (event) {
                                if (event.keyCode === 13) {
                                  event.currentTarget.blur();
                                }
                              }}
                              onBlur={(event) => {
                                const newGroupName = event.target.value;
                                if (newGroupName.length > 20) {
                                  setChangeGroupNameIndex(-1);
                                  return showError(t('分组名长度请限制在20个字符以内'));
                                }
                                setLoadingIndex(index);
                                modifyData((data) =>
                                  data.map((item) => {
                                    if (item.group === groupName) {
                                      item.group = newGroupName;
                                    }
                                    return item;
                                  }),
                                ).then((data) => {
                                  setTableData(data);
                                  setLoadingIndex(-1);
                                  setChangeGroupNameIndex(-1);
                                });
                              }}
                              className={classes.rename}
                            />
                          </>
                        ) : (
                          <div className={classes.listItemGroupName}>{groupName}</div>
                        )}
                        {index === loadingIndex && <DSCircularProgress size={16} />}
                      </div>
                    }
                  />
                </ListItem>
                <Collapse className={classes.collapse} in={openIndex.includes(index)} timeout="auto" unmountOnExit>
                  <DSTable
                    tableHead={tableHead}
                    showTableHead={false}
                    align={'left'}
                    padding={'none'}
                    cellProps={{
                      title: {
                        size: 'small',
                        padding: 'none',
                      },
                      content: {
                        padding: 'none',
                      },
                      operation: {
                        padding: 'none',
                      },
                    }}
                    stickyHeader={true}
                    loading={commKVLoading}
                    error={commKVErrorState ? commKVMessageState : ''}
                    tableData={parsedTableData(data[groupName])}
                  />
                </Collapse>
              </div>
            ))}
          </List>
          <BaseDialog
            loading={dialogLoading}
            paperClass={classes.dialogPaper}
            open={dialogOpen}
            title={t('快捷回复')}
            onClose={() => {
              setNewData({
                content: '',
              });
              setDialogOpen(false);
              setDataError({});
              setAddGroupName(false);
            }}
            actions={{
              确定: {
                onClick: () => {
                  const { content } = newData;
                  const errorMessage = {};
                  const { title } = newData;
                  const { group } = newData;
                  if (title.length > 20) {
                    errorMessage.title = t('标题长度不超过20个字符');
                  }
                  if (group.length > 20) {
                    errorMessage.group = t('分组名长度不超20个字符');
                  }
                  if (content.trim() === '') errorMessage.content = t('快捷回复内容不能空');
                  if (title.trim() === '') errorMessage.title = t('标题内容不能为空');
                  if (group.trim() === '') errorMessage.group = t('分组不能为空');
                  if (errorMessage.title || errorMessage.content || errorMessage.group) {
                    setDataError(errorMessage);
                    return;
                  }
                  setDialogLoading(true);
                  modifyData((data) => {
                    const tableData = data;
                    if (
                      !tableData.some((item, index) => {
                        const has = item.id === newData.id;
                        if (has) {
                          tableData[index] = newData;
                        }
                        return has;
                      })
                    ) {
                      tableData.push(newData);
                    }
                    return tableData;
                  })
                    .then((data) => {
                      setTableData(data);
                      setDialogLoading(false);
                      setDialogOpen(false);
                      setAddGroupName(false);
                      const groups = uniqBy(data, 'group').map((e) => e.group);
                      setGroups(groups);
                      setNewData({
                        content: '',
                        title: '',
                        group: '',
                        id: '',
                      });
                    })
                    .catch(() => {
                      setDialogLoading(false);
                    });
                },
              },
            }}
          >
            {!addGroupName ? (
              <div>
                <DSSelect
                  selectProps={{
                    className: classes.addDialogSelect,
                    classes: {},
                  }}
                  label={t('分组')}
                  options={groups.includes(newData.group) ? groups : groups.concat([newData.group])}
                  value={newData.group}
                  onChange={changeData.bind(this, 'group')}
                  error={!!dataError.group}
                />
                <Button
                  onClick={() => {
                    setAddGroupName(true);
                  }}
                >
                  {t('添加分组')}
                </Button>
              </div>
            ) : (
              <div>
                <TextField
                  label={t('分组')}
                  onChange={changeData.bind(this, 'group')}
                  value={newData.group}
                  required
                  onFocus={() => setDataError({ group: '' })}
                  error={!!dataError.group}
                  autoFocus={true}
                />
                <Button
                  onClick={() => {
                    setAddGroupName(false);
                  }}
                >
                  {t('取消')}
                </Button>
              </div>
            )}
            <TextField
              label={t('标题')}
              placeholder={t('标题')}
              onChange={changeData.bind(this, 'title')}
              required
              value={newData.title}
              onFocus={() => setDataError({ title: '' })}
              error={!!dataError.title}
            />
            <TextField
              InputProps={{
                className: classes.addDialogInput,
              }}
              label={t('快捷回复内容')}
              placeholder={t('“Shift+Enter”键换行')}
              onChange={changeData.bind(this, 'content')}
              className={classes.addDialogTextField}
              required
              value={newData.content}
              multiline={true}
              data-key={'content'}
              rows={4}
              error={!!dataError.content}
              onFocus={() => setDataError({ content: '' })}
            />
            <p className={classes.dataError}>
              {Object.keys(dataError)
                .map((key) => dataError[key])
                .join(';')}
            </p>
          </BaseDialog>
        </Paper>
      </Box>
    </Fragment>
  );
}

export default QuickReply;
