import * as t from 'io-ts';

import { nullableArray } from 'src/utils/codecs';

export const seatApis = {
  '/wxwork/getMemberList': {
    Input: t.type({
      suiteCategory: t.string,
      fuzzingSearchKey: t.string,
      pageSize: t.string,
      pageNumber: t.string,
    }),
    Output: t.type({
      totalCount: t.string,
      userInfoList: nullableArray(
        t.type({
          userId: t.string,
          userName: t.string,
        }),
      ),
    }),
  },
  '/tccclogin/login/queryUserInstanceList': {
    Input: t.type({}),
    Output: t.type({
      userInstanceList: nullableArray(
        t.type({
          sdkAppId: t.string,
          instanceName: t.string,
          userId: t.string,
          userName: t.string,
          nickName: t.string,
          roleId: t.string,
          staffNo: t.string,
          avatar: t.string,
          skillGroupId: t.array(t.string),
          hasAdmin: t.boolean,
          hasWorkbench: t.boolean,
          hasDeskAdmin: t.boolean,
        }),
      ),
    }),
  },
};
