export enum SpecialRoleId {
  ADMIN = '1', // 超级管理员
  INSPECTOR = '2', // 质检员
  SEAT = '3', // 员工
  VOICE_SEAT = '4', // 语音员工
  VOICE_ADMIN = '6', // 语音管理员
  IM_SEAT = '5', // 在线员工
  IM_ADMIN = '7', // 在线管理员
}

export enum SpecialPermission {
  // 在线客服工作台
  PermissionChatWorkBench = 'CHAT_WORKBENCH',
  // 电话客服工作台
  PermissionVoiceWorkBench = 'VOICE_WORKBENCH',
  // PermissionLegacyAudioWorkbench 音频工作台
  PermissionLegacyAudioWorkbench = 'LEGACY_AUDIO_WORKBENCH',
  // PermissionLegacyVideoWorkbench 视频工作台
  PermissionLegacyVideoWorkbench = 'LEGACY_VIDEO_WORKBENCH',

  // 电话音频ivr节点
  // PermissionVoiceIVRCondition 条件判断节点
  PermissionVoiceIVRCondition = 'VOICE_IVR_CONDITION',
  // PermissionVoiceIVRInterfaceInvocation 接口调用节点
  PermissionVoiceIVRInterfaceInvocation = 'VOICE_IVR_INTERFACE_INVOCATION',
  // PermissionVoiceIVROutbound 转外线节点
  PermissionVoiceIVROutbound = 'VOICE_IVR_OUTBOUND',
  // PermissionVoiceIVRInputDtmf 收号节点
  PermissionVoiceIVRInputDtmf = 'VOICE_IVR_INPUT_DTMF',
  // PermissionVoiceIVRVoicemail 语音信箱节点，
  PermissionVoiceIVRVoicemail = 'VOICE_IVR_VOICEMAIL',
  // PermissionVoiceIVRAsrMenu 语音菜单节点,语音识别导航
  PermissionVoiceIVRAsrMenu = 'VOICE_IVR_ASR_MENU',
  // 转人工节点. 在智能语音交互下是没有的,标准版、高级版本是有的
  PermissionVoiceIVRSkillGroup = 'VOICE_IVR_SKILLGROUP',
  // PermissionVoiceIVRExtension 内线节点，
  PermissionVoiceIVRExtension = 'VOICE_IVR_EXTENSION',
  // PermissionVoiceIVRSkillGroupOverflow 转人工溢出,技能组溢出
  PermissionVoiceIVRSkillGroupOverflow = 'VOICE_IVR_SKILL_GROUP_OVERFLOW',
  // PermissionVoiceIVRSkillGroupCustomURL 转人工自定义url。指定座席优先
  PermissionVoiceIVRSkillGroupCustomURL = 'VOICE_IVR_SKILL_GROUP_CUSTOM_URL',
  // PermissionVoiceIVRSkillGroupPriority 转人工优先级，指定座席优先，队列优先级
  PermissionVoiceIVRSkillGroupPriority = 'VOICE_IVR_SKILL_GROUP_PRIORITY',
  // PermissionVoiceIVRSkillGroupFallback 转人工回退，开启转人工失败的
  PermissionVoiceIVRSkillGroupFallback = 'VOICE_IVR_SKILL_GROUP_FALLBACK',
  // PermissionVoiceIVRDynamicMenu 动态菜单，动态分支
  PermissionVoiceIVRDynamicMenu = 'VOICE_IVR_DYNAMIC_MENU',
  // PermissionVoiceIVRVariable 变量相关功能，存储按键信息到变量,变量相关功能
  PermissionVoiceIVRVariable = 'VOICE_IVR_VARIABLE',
  // PermissionVoiceCalloutPreferLocation 呼出归属地匹配
  PermissionVoiceCalloutPreferLocation = 'VOICE_CALLOUT_PREFER_LOCATION',
  // PermissionVoiceNumberReflect 号码映射
  PermissionVoiceNumberReflect = 'VOICE_NUMBER_REFLECT',

  // im ivr节点, 收集信息
  // im-bot1 ,imBotGray,im-bot --->文机器人，越来后端的白名单，通过/tcccadmin/app/getAppInfo返回 imBotGray
  // PermissionIMIVRBot 机器人节点,客服机器人。imbot2。imBot2Gray。im-bot2-->客服机器人
  PermissionIMIVRBot = 'IM_IVR_BOT',
  // PermissionIMIVRCondition 条件判断节点
  PermissionIMIVRCondition = 'IM_IVR_CONDITION',
  // PermissionIMIVRInterfaceInvocation 接口调用节点
  PermissionIMIVRInterfaceInvocation = 'IM_IVR_INTERFACE_INVOCATION',
  // PermissionIMIVRFormSave 表单保存节点.收集信息
  PermissionIMIVRFormSave = 'IM_IVR_FORM_SAVE',

  // 预测式外呼
  PermissionTelPredictiveCallout = 'TEL_CALLOUT_TASK', // TEL_CALLOUT_TASK
  // 电话自助服务, TEL_SELF_HELP_CONFIG
  PermissionTelSelfHelpConfig = 'TEL_SELF_HELP_CONFIG',
  // 音频自助服务, RADIO_SELF_HELP_CONFIG
  PermissionRadioSelfHelpConfig = 'RADIO_SELF_HELP_CONFIG',

  // PermissionVoiceIVRCallout 呼出ivr .
  PermissionVoiceIVRCallout = 'VOICE_IVR_CALLOUT',
  // PermissionVoiceIVRSelfService 自助服务ivr,自动外呼IVR。包括电话、音频的
  PermissionVoiceIVRSelfService = 'VOICE_IVR_SELF_SERVICE',
  // PermissionVoiceIVRAutoCallout 自动外呼ivr
  PermissionVoiceIVRAutoCallout = 'VOICE_IVR_AUTO_CALLOUT',

  // page:实时监控---》 技能组监控的细分。
  // 客服通话监听,实时通话监控
  PermissionCallMonitor = 'REALTIME_CALL_MONITOR',
  PermissionVoiceSkillGroupMonitor = 'VOICE_SKILL_GROUP_MONITOR', // 电话
  PermissionChatSkillGroupMonitor = 'CHAT_SKILL_GROUP_MONITOR', // IM
  // Deprecated: 旧音频客服技能组监控项
  PermissionAudioSkillGroupMonitor = 'AUDIO_SKILL_GROUP_MONITOR', // 音频
  PermissionVideoSkillGroupMonitor = 'VIDEO_SKILL_GROUP_MONITOR', // 视频

  // page:数据看板--> 客服数据
  PermissionRealtimeCallMonitor = 'SEAT_STAT_DASHBOARD', // 实时通话监控
  PermissionRealtimeCallMonitorTel = 'REALTIME_CALL_MONITOR_TEL', // 电话
  PermissionRealtimeCallMonitorAudio = 'REALTIME_CALL_MONITOR_AUDIO', // 音频
  PermissionRealtimeCallMonitorVideo = 'REALTIME_CALL_MONITOR_VIDEO', // 视频

  // page: 黑名单的细分
  PermissionVoiceBlacklistConfig = 'VOICE_BLACKLIST_CONFIG',
  PermissionChatBlacklistConfig = 'CHAT_BLACKLIST_CONFIG',

  // page: 数据看板 ---》 在线与音视频数据 .细分 PermissionVideoStats。 。 这里原有的英文命名占了video的位置，所以video得换个名字
  PermissionChatStats = 'CHAT_STAT_DASHBOARD',
  PermissionAudioStats = 'AUDIO_STAT_DASHBOARD',
  PermissionVideo2Stats = 'VIDEO2_STAT_DASHBOARD',

  // PermissionVoiceIVRMultiVersion 多版本ivr
  PermissionVoiceIVRMultiVersion = 'VOICE_IVR_MULTI_VERSION',

  // PermissionVoiceSeatExtension 座席绑定话机。sip话机。(高级版才可以使用)
  PermissionVoiceSeatExtension = 'VOICE_SEAT_EXTENSION',
  // PermissionVoiceSeatConfigExtension 座席绑定分机号.软电话内线号.软电话。（普通版本是可用）
  PermissionVoiceSeatConfigExtension = 'VOICE_SEAT_CONFIG_EXTENSION',

  // PermissionVoiceNumberSpecialForward 号码转接.回呼优先接回原座席
  // Deprecated: 等变量转人工上线后废弃
  PermissionVoiceNumberSpecialForward = 'VOICE_NUMBER_SPECIAL_FORWARD',

  // 细分新手引导
  PermissionVoiceGuide = 'VOICE_GUIDE', // 电话
  PermissionChatGuide = 'CHAT_GUIDE', // IM
  PermissionAudioGuide = 'AUDIO_GUIDE', // 音频
  PermissionVideoGuide = 'VIDEO_GUIDE', // 视频

  // page:数据分析.
  PermissionDataOverviewTel = 'TEL_SEAT_STATS',
  PermissionDataOverviewIM = 'IM_SEAT_STATS',
  PermissionDataOverviewRadio = 'RADIO_SEAT_STATS',
  PermissionDataOverviewVideo = 'VIDEO_SEAT_STATS',
  PermissionDataOverviewAllSeat = 'ALL_SEAT_STATS',
  PermissionDataOverviewSeatTrace = 'SEAT_STATUS_TRACE_STATS',
  PermissionDataOverviewNumber = 'NUMBER_HEALTH_STATS',
  // page:服务记录
  PermissionServiceRecordTel = 'TEL_SEAT_CDR', // 电话客服
  PermissionServiceRecordIm = 'IM_SEAT_SERVICE_LOG', // 在线客服
  PermissionServiceRecordRadio = 'RADIO_SEAT_SERVICE_LOG', // 音频客服
  PermissionServiceRecordVideo = 'VIDEO_SEAT_SERVICE_LOG', // 视频客服
  PermissionServiceRecordInternal = 'INTERNAL_LINE_SERVICE_LOG', // 内线通话

  // 原有seat_type=3 拥有PermissionChatChannelIMAgent
  // 原有seat_type=4 拥有PermissionChatChannelIMDesk
  // 两者前端展示都是im渠道 但是im_agent没有增删的功能
  PermissionChatChannelIMAgent = 'CHAT_CHANNEL_IM_AGENT',
  PermissionChatChannelIMDesk = 'CHAT_CHANNEL_IM_DESK',
}
// 缺少权限
// 视频客服

export const getRoleNameList = (roleObjectList: { roleId: string; roleName: string }[], roleId?: string) => {
  const roleIdList = (roleId || '').split(',');
  const nameList = [];
  for (const itemRoleId of roleIdList) {
    const role = (roleObjectList || []).find((id) => id.roleId === itemRoleId);
    if (role) {
      nameList.push(`${role.roleName}`);
    }
  }
  return nameList;
};
// role id 为 "1,3"或者是 "1"
export const isHaveRoleById = (roleId?: string, id?: string | SpecialRoleId) => {
  const roleIdList = (roleId || '').split(',');
  return roleIdList.includes(`${id}`);
};
export const isHavePermission = (permissionKey: string, permissionList?: string[]) => {
  if (permissionList) {
    return permissionList.includes(permissionKey);
  }
  return false;
};
