/// <reference types="node" />
export const DEV_DOMAIN = 'dev.tccc.qcloud.com';
export const TEST_DOMAIN = 'test.tccc.qcloud.com';
export const PROD_DOMAIN = 'tccc.qcloud.com';
export const INTL_DOMAIN = 'connect.tencentcloud.com';
export const COM_CN_DOMAIN = 'ccc.cloud.tencent.com.cn';
export const CN_DOMAIN = 'ccc.cloud.tencent.cn';

export const DESK_DEV_DOMAIN = 'test-desk.qcloud.com';
export const DESK_PROD_DOMAIN = 'desk.qcloud.com';
export const DESK_INTL_DOMAIN = 'desk.tencentcloud.com';
export const DESK_DEV_INTL_DOMAIN = 'test-desk.tencentcloud.com';

export const API_PROD_DOMAIN =
  process.env.REACT_APP_API_DOMAIN || import.meta.env.VITE_APP_API_DOMAIN || 'api.tccc.qcloud.com';
export const API_INTL_DOMAIN = 'api.connect.tencentcloud.com';
export const API_COM_CN_DOMAIN = 'api.ccc.cloud.tencent.com.cn';
export const API_CN_DOMAIN = 'api.ccc.cloud.tencent.cn';

export const API_BAK_PROD_DOMAIN = process.env.REACT_APP_API_BAK_DOMAIN || import.meta.env.VITE_APP_API_BAK_DOMAIN;

export const OTLP_DOMAIN = 'otlp.tccc.qcloud.com';
export const OTLP_INTL_DOMAIN = 'otlp.connect.tencentcloud.com';

export const OTLP_ID = 'tccc';
export const OTLP_INTL_ID = 'tcccsg';
export const OTLP_ENV = process.env.REACT_APP_MODE || 'local';

export const AEGIS_ID = process.env.REACT_APP_AEGIS || import.meta.env.VITE_APP_AEGIS;
export const AEGIS_INTL_ID = '';
export const AEGIS_URL = 'https://aegis.qq.com';
export const AEGIS_INTL_URL = '';

export const CAPTCHA_URL = 'https://turing.captcha.qcloud.com/TCaptcha.js';
export const CAPTCHA_INTL_URL = 'https://ca.turing.captcha.qcloud.com/TCaptcha-global.js';
export const CAPTCHA_MOBILE_ID = '2032631230';
export const CAPTCHA_INTL_MOBILE_ID = '189962277';
export const CAPTCHA_LOGIN_ID = '2094577569';
export const CAPTCHA_INTL_LOGIN_ID = '189954088';
