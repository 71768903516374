import Reporter from '@tencent/tongxin-log-reporter';
import * as history from 'history';
import { OTLP_ENV } from 'tccc-env/src/constant';
import { GET_OTLP_DOMAIN, GET_OTLP_ID } from 'tccc-env/src/url';
import { ulid } from 'ulid';

import { COMMIT_ID, isFromSDK } from 'src/constants/env';

const envName = OTLP_ENV;

const uuid = ulid();
const getUUID = (id: string): string => {
  try {
    const key = `__${id}__uuid`;
    const deviceId = window.localStorage.getItem(key) as string;
    if (deviceId !== null) {
      return deviceId;
    }

    window.localStorage.setItem(key, uuid);

    return uuid;
  } catch (e) {
    // 无痕模式
    return uuid;
  }
};
const isSDK = isFromSDK;

export const logger = new Reporter({
  id: GET_OTLP_ID(),
  service: isSDK ? 'tccc-iframe' : 'tccc-workstation',
  logUrl: `https://${GET_OTLP_DOMAIN()}/v1/logs`,
  traceUrl: `https://${GET_OTLP_DOMAIN()}/v1/traces`,
  collectDataSet: 'collect',
  reportSpeed: true,
  reportError: true,
  reportRouter: true,
  reportLoad: !isSDK,
  ignoreUrl: [
    /\/ccc\/debug\/seatLog/,
    /\/tcccadmin\/feedbackqueue\/queryQueueCount/,
    /\/ccc\/report\/seatConnectionState/,
    /\/ccc\/report\/seatNetworkQuality/,
    /AVQualityReportSvc/,
    /https:\/\/tpstelemetry.tencent.com/,
    /https:\/\/otlp.tccc.qcloud.com/,
    /https:\/\/otlp.connect.tencentcloud.com/,
    /https:\/\/qcloudimg.tencent-cloud.cn/,
    /https:\/\/schedule.rtc.qcloud.com/,
    /https:\/\/schedule.rtc.qq.com/,
    /https:\/\/aegis.qq.com/,
    /https:\/\/imgcache.qq.com/,
  ],
  attributes: {
    module: 'saas',
    uuid: getUUID('tccc'),
    env_name: envName,
    commit: COMMIT_ID,
  },
  beforeReport(args) {
    if (process.env.NODE_ENV === 'development') return;
    if (args.type === 'history-change') {
      try {
        return {
          to: history.parsePath(args.to).pathname.replace(/\/14\w{8}/, ''),
          from: history.parsePath(args.from).pathname.replace(/\/14\w{8}/, ''),
        } as any;
      } catch (e) {}
    }

    if (args.type === 'xhr-report') {
      try {
        const res = JSON.parse(args.response);
        if (res.errorCode) {
          return {
            isSuccess: res.errorCode !== '-999',
            errorCode: res.errorCode,
            requestId: res.requestId,
            response: undefined,
          };
        }
      } catch (e) {}
    }
  },
});
