import { PhoneNumberUtil } from 'google-libphonenumber';
const phoneUtil = PhoneNumberUtil.getInstance();

const formatPhoneNumber = (telStr: string, pattern: RegExp, useMask: boolean, splitText: string): string =>
  telStr.replace(pattern, useMask ? ['$1', '****', '$3'].join('') : ['$1', '$2', '$3'].join(splitText));

const maskIntlNumber = (telStr: string) => {
  const l = telStr.length;
  if (l > 7) {
    const pattern = new RegExp(`(\\d{3})(\\d{4})(\\d{${l - 7}})`);
    return formatPhoneNumber(telStr, pattern, true, '');
  }
  return telStr.replace(/.*(?=.{4}$)/, '****');
};

/**
 * 中国大陆地区的座机号码格式化
 * https://zh.wikipedia.org/zh-hans/%E4%B8%AD%E5%9B%BD%E5%A4%A7%E9%99%86%E7%94%B5%E8%AF%9D%E5%8F%B7%E7%A0%81
 *
 */
const parseLandlineNumber = (telStr: string, useMask: boolean, splitText: string) => {
  let res = `${telStr}`;

  const regionCodeLength = res.startsWith('01') || res.startsWith('02') ? 3 : 4;
  const phoneNumberLength = res.length - regionCodeLength;
  if (phoneNumberLength === 7) {
    const pattern = new RegExp(`(\\d{${regionCodeLength}})(\\d{3})(\\d{4})`);
    res = formatPhoneNumber(res, pattern, useMask, splitText);
  } else if (phoneNumberLength === 8) {
    const pattern = new RegExp(`(\\d{${regionCodeLength}})(\\d{4})(\\d{4})`);
    res = formatPhoneNumber(res, pattern, useMask, splitText);
  }
  return res;
};

const formattedPhoneForCN = (telStr: string, useMask: boolean, splitText: string) => {
  let res = `${telStr}`;
  // 0086开头
  if (telStr.startsWith('0086')) {
    res = res.replace(/^0086/, '');
  }
  if (telStr.startsWith('+86')) {
    res = res.replace(/^\+86/, '');
  }
  // 电话号码
  if (res.startsWith('0')) {
    res = parseLandlineNumber(res, useMask, splitText);
  } else if (res.startsWith('1') && res.length === 11) {
    // 手机号码
    res = formatPhoneNumber(res, /(\d{3})(\d{4})(\d{4})/, useMask, splitText);
  } else if (telStr.startsWith('+86')) {
    res = parseLandlineNumber(`0${res}`, useMask, splitText);
  }
  return telStr.startsWith('+86') ? `+86${res}` : res;
};

export const formatTel = (telStr: string, useMask = false, splitText = ' ') => {
  if (!telStr) return telStr;
  try {
    let res = `${telStr}`;
    // eslint-disable-next-line no-param-reassign
    splitText = splitText || ' ';
    const numberInstance = phoneUtil.parseAndKeepRawInput(telStr, 'CN');

    if (numberInstance.getExtension()) {
      return `${parseLandlineNumber(
        `0${numberInstance.getNationalNumber()}`,
        useMask,
        splitText,
      )},${numberInstance.getExtension()}`;
    }

    const countryCode = numberInstance.getCountryCode() || '';
    if (countryCode && countryCode !== 86) {
      let prefix = '';
      if (res.startsWith('00')) {
        prefix = `00${countryCode}`;
        res = res.replace(new RegExp(`^${prefix}`), '');
      } else if (res.startsWith('+')) {
        prefix = `+${countryCode}`;
        res = res.replace(new RegExp(`^${prefix}`), '');
      }
      if (useMask) {
        res = maskIntlNumber(res);
      }
      return telStr.startsWith('+') ? [`+${countryCode}`, res].join(splitText) : res;
    }
    // 查询不到 country 或者 country为 86 则认为是国内号码做处理
    return formattedPhoneForCN(res, useMask, splitText);
  } catch (e) {
    return telStr;
  }
};

export const DTMF_REGEXP = /[^\d#*a-d,]/g; // DTMF不允许输入+
export const PHONE_REGEXP = /[^\d#*+a-d,]/g; // 允许的号码格式
export const PLAIN_PHONE = /[^\d#*+a-d]/g; // 不带分机号的号码
