/* eslint-disable no-param-reassign */
import 'core-js/stable';
import ReactDOM from 'react-dom';

import 'regenerator-runtime/runtime';
import './aegis';

import { useEffect } from 'react';

import loadable from '@loadable/component';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { ConfigProvider } from '@tencent/tea-component';
import en from '@tencent/tea-component/lib/i18n/locale/en_US';
import zh from '@tencent/tea-component/lib/i18n/locale/zh_CN';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import { ErrorBoundary } from 'react-error-boundary';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';

import Tips from 'src/components/Tips/Tips';
import ConfirmDialogTips from 'src/components/universal/ConfirmDialogTips/ConfirmDialogTips';
import store from 'src/store';
import { global } from 'src/themes/global';
import { logger } from 'src/utils/log';
import { getNewUserAudioStr, getPhoneSvgStr, getRadioSVgStr, getVideoSvgStr } from 'src/utils/preLoginAction';

import { MyProfile } from './components/MyProfile/MyProfile';
import { deprecatedOpenHTML } from './constants/env';
import { ErrorBoundaryFallback } from './ErrorBoundaryFallback';
import i18n from './i18n';
import RouterBeforeEnter from './RouterBeforeEnter';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'src/assets/css/material-dashboard-react.css?v=1.7.0';

import '@tencent/tea-component/dist/tea.css';
import './utils/tccc';
const SdkMain = loadable(() => import('./agentSdkIframe/Main/Main'));
function preserveQueryParameters(history, preserve, location) {
  const currentQuery = queryString.parse(history.location.search);
  if (currentQuery) {
    const preservedQuery = {};
    for (const p of preserve) {
      const v = currentQuery[p];
      if (v) {
        preservedQuery[p] = v;
      }
    }
    if (location.search) {
      Object.assign(preservedQuery, queryString.parse(location.search));
    }
    // eslint-disable-next-line no-param-reassign
    if (!['/online', '/login', '/'].includes(location.pathname)) {
      location.search = queryString.stringify(preservedQuery);
    }
  }
  return location;
}

function createLocationDescriptorObject(path, state) {
  if (typeof path === 'string') {
    const url = new URL(path, `https://${window.location.hostname}`);
    return {
      search: url.search,
      pathname: url.pathname,
      hash: url.hash,
      state,
    };
  }
  return path;
}

function createPreserveQueryHistory(createHistory, queryParameters) {
  return (options) => {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    const history = createHistory({ ...{ basename: '' }, ...options });
    const oldPush = history.push;
    const oldReplace = history.replace;
    history.push = (path, state) =>
      oldPush.apply(history, [
        preserveQueryParameters(history, queryParameters, createLocationDescriptorObject(path, state)),
      ]);
    history.replace = (path, state) =>
      oldReplace.apply(history, [
        preserveQueryParameters(history, queryParameters, createLocationDescriptorObject(path, state)),
      ]);
    return history;
  };
}

const history = createPreserveQueryHistory(createBrowserHistory, [
  'fromSdk',
  'hideHeader',
  'hideSideBar',
  'demoFeature',
  'from',
  'source',
])();

const logError = (error, info) => {
  console.log(error);
  console.log(info);
  logger.error('ErrorBoundary上报');
  logger.error(error);
  logger.error(info);
};

const render = (Component) =>
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={global}>
        <I18nextProvider i18n={i18n}>
          <CssBaseline />
          {/* 这样浏览器是不生效的、as="audio" rel="preload" 所以写为as="fetch" */}
          <div
            id="div-preload"
            dangerouslySetInnerHTML={{
              __html: `
          <link href="${getNewUserAudioStr()}" as="fetch" rel="preload" />
          <link href="${getPhoneSvgStr()}" as="image" rel="preload" />
          <link href="${getRadioSVgStr()}" as="image" rel="preload" />
          <link href="${getVideoSvgStr()}" as="image" rel="preload" />
          `,
            }}
          ></div>
          <Tips />
          <ConfirmDialogTips />
          <Router history={history}>
            <Component />
          </Router>
        </I18nextProvider>
      </ThemeProvider>
    </Provider>,
    document.getElementById('root'),
  );

function App() {
  const { i18n, t } = useTranslation();
  useEffect(() => {
    const { from } = queryString.parse(window.location.search);
    if (from !== 'exp') {
      document.body.style.backgroundColor = '#fff';
    } else {
      document.body.style.display = 'flex';
      document.body.style.alignItems = 'center';
      document.body.style.justifyContent = 'center';
      document.body.style.border = '1px solid transparent';
      document.body.style.borderRadius = '8px';
    }
    document.title = t('联络中心');
  }, []);
  return (
    <ConfigProvider locale={i18n.language === 'en' ? en : zh}>
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={logError}>
        <Switch>
          <Route path="/(open)/:sdkAppId?">
            <SdkMain />
          </Route>
          <Route path="/(open.html)/:sdkAppId?">
            <SdkMain />
          </Route>
          <Route path={`${deprecatedOpenHTML}`}>
            <SdkMain />
          </Route>
          <Route path="/agent/:sdkAppId/myProfile">
            <MyProfile />
          </Route>
          <Route>
            <RouterBeforeEnter />
          </Route>
        </Switch>
      </ErrorBoundary>
    </ConfigProvider>
  );
}

render(App);
